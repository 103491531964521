import { configureStore } from "@reduxjs/toolkit";
import feedbackReducer from "./feedbackSlice";
import authReducer from "./authSlice";

const store = configureStore({
    reducer: {
        feedback: feedbackReducer,
        auth: authReducer,
    },
});

export default store;
