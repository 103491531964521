import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { useAuth } from "../providers/AuthProvider";
import { db } from "../firebase/setup";
import {
    getDoc,
    getDocs,
    doc,
    collection,
    query,
    where,
} from "firebase/firestore";
import { Box } from "@mui/material";
import { collectionName } from "../constObjects";
import RosterList from "../components/RosterList";
import ProfileCard from "../components/ProfileCard";
import { useAuth } from "../providers/AuthProvider";

const Profile = () => {
    const { currentUser } = useAuth();
    const { uid } = useParams();
    const isOwn = currentUser.uid === uid;
    const [user, setUser] = useState({
        logoUrl: "",
        team: "",
        manager: "",
        president: "",
    });
    const [gkList, setGkList] = useState([]);
    const [dfList, setDfList] = useState([]);
    const [mfList, setMfList] = useState([]);
    const [fwList, setFwList] = useState([]);

    useEffect(() => {
        const getUser = async () => {
            const userSnap = await getDoc(doc(db, collectionName.users, uid));
            userSnap.exists() && setUser({ ...userSnap.data(), uid });
            return true;
        };

        getUser();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const getRoster = async () => {
            const playersRef = collection(db, collectionName.players);
            // get gk
            const gkSnapshot = await getDocs(
                query(
                    playersRef,
                    where("team", "==", user.team),
                    where("position", "==", "gk")
                )
            );
            if (!gkSnapshot.empty) {
                gkSnapshot.docs.forEach((doc) => {
                    setGkList((prev) => [...prev, doc.data()]);
                });
            }
            // get df
            const dfSnapshot = await getDocs(
                query(
                    playersRef,
                    where("team", "==", user.team),
                    where("position", "==", "df")
                )
            );
            if (!dfSnapshot.empty) {
                dfSnapshot.docs.forEach((doc) => {
                    setDfList((prev) => [...prev, doc.data()]);
                });
            }
            // get mf
            const mfSnapshot = await getDocs(
                query(
                    playersRef,
                    where("team", "==", user.team),
                    where("position", "==", "mf")
                )
            );
            if (!mfSnapshot.empty) {
                mfSnapshot.docs.forEach((doc) => {
                    setMfList((prev) => [...prev, doc.data()]);
                });
            }
            // get fw
            const fwSnapshot = await getDocs(
                query(
                    playersRef,
                    where("team", "==", user.team),
                    where("position", "==", "fw")
                )
            );
            if (!fwSnapshot.empty) {
                fwSnapshot.docs.forEach((doc) => {
                    setFwList((prev) => [...prev, doc.data()]);
                });
            }
        };
        getRoster();
    }, [user.team]);

    return (
        <Box
            component="div"
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 2,
                width: "100%",
            }}
        >
            <ProfileCard
                key={`profile-card-${uid}`}
                team={user}
                isEditable={currentUser?.admin || isOwn}
            />
            <RosterList
                gkList={gkList}
                dfList={dfList}
                mfList={mfList}
                fwList={fwList}
            />
        </Box>
    );
};

export default Profile;
