import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";

const PrivateRoutes = ({ children }) => {
    const { currentUser } = useAuth();
    const location = useLocation();

    if (!currentUser)
        return <Navigate to="/giris" state={{ from: location }} replace />;
    return children;
};

export default PrivateRoutes;
