import { CircularProgress, Backdrop } from "@mui/material";
import { useSelector } from "react-redux";

const Spinner = () => {
    const loading = useSelector((state) => state.feedback.loading) || false;

    return (
        <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
            <CircularProgress />
        </Backdrop>
    );
};

export default Spinner;
