import { collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../firebase/setup";
import { collectionName } from "../constObjects";

const ExportImport = () => {
    const [users, setUsers] = useState([]);
    const [drafts, setDrafts] = useState([]);
    const [players, setPlayers] = useState([]);

    useEffect(() => {
        const getUsers = async () => {
            const usersRef = collection(db, collectionName.users);
            const usersSnapshot = await getDocs(usersRef);
            if (!usersSnapshot.empty) {
                usersSnapshot.docs.forEach((doc) => {
                    setUsers((prev) => [...prev, doc.data()]);
                });
            }
        };
        const getDrafts = async () => {
            const draftsRef = collection(db, collectionName.drafts);
            const draftsSnapshot = await getDocs(draftsRef);
            if (!draftsSnapshot.empty) {
                draftsSnapshot.docs.forEach((doc) => {
                    setDrafts((prev) => [...prev, doc.data()]);
                });
            }
        };
        const getPlayers = async () => {
            const playersRef = collection(db, collectionName.players);
            const playersSnapshot = await getDocs(playersRef);
            if (!playersSnapshot.empty) {
                playersSnapshot.docs.forEach((doc) => {
                    setPlayers((prev) => [...prev, doc.data()]);
                });
            }
        };

        getUsers();
        getDrafts();
        getPlayers();
    }, []);

    return (
        <div className="flex flex-col space-y-4 text-lightOnSurface dark:text-darkOnSurface">
            <div>{JSON.stringify(users)}</div>
            <div>{JSON.stringify(drafts)}</div>
            <div>{JSON.stringify(players)}</div>
        </div>
    );
};

export default ExportImport;
