import { db } from "../firebase/setup";
import {
    doc,
    updateDoc,
    getDocs,
    getDoc,
    addDoc,
    serverTimestamp,
    where,
    query,
    collection,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import {
    collectionName,
    errorCodes,
    formatDateDDMMYYYY,
    severity,
} from "../constObjects";
import { setLoading, setFeedback } from "../store/feedbackSlice";
import { useDispatch } from "react-redux";
import {
    Container,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    Button,
    Typography,
    Box,
    Paper,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import KAPCard from "../components/KAPCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const Kap = () => {
    const { currentUser } = useAuth();
    const [tradeOpen, setTradeOpen] = useState(null);
    const [kapList, setKapList] = useState([]);
    const [teamList, setTeamList] = useState([]);
    const [team1PlayerList, setTeam1PlayerList] = useState([]);
    const [team2PlayerList, setTeam2PlayerList] = useState([]);
    const [team1, setTeam1] = useState("");
    const [team2, setTeam2] = useState("");
    const [give1, setGive1] = useState("");
    const [give2, setGive2] = useState("");

    const redirect = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const getControlData = async () => {
            const controlSnap = await getDoc(
                doc(db, collectionName.control, "control_doc")
            );
            if (controlSnap.exists()) {
                setTradeOpen(controlSnap.data().tradeOpen);
            }
        };

        // get team list
        const getTeamList = async () => {
            const usersRef = collection(db, collectionName.users);
            const usersSnapshot = await getDocs(usersRef);
            if (!usersSnapshot.empty) {
                usersSnapshot.docs.forEach((doc) => {
                    setTeamList((prev) => [...prev, doc.data().team]);
                });
            }
        };
        // TODO: get all kap
        const getKapList = async () => {
            const kapsRef = collection(db, collectionName.kaps);
            const kapsSnapshot = await getDocs(kapsRef);
            if (!kapsSnapshot.empty) {
                kapsSnapshot.docs.forEach((doc) => {
                    setKapList((prev) => [
                        ...prev,
                        { ...doc.data(), kapId: doc.id },
                    ]);
                });
            }
        };

        //eslint-disable-next-line
        getControlData();
        getTeamList();
        getKapList();
    }, []);

    useEffect(() => {
        setTeam1PlayerList([]);
        const getTeam1Players = async () => {
            const team1PlayersListRef = collection(db, collectionName.players);
            const team1PlayersListSnapshot = await getDocs(
                query(team1PlayersListRef, where("team", "==", team1))
            );
            if (!team1PlayersListSnapshot.empty) {
                team1PlayersListSnapshot.docs.forEach((doc) => {
                    setTeam1PlayerList((prev) => [
                        ...prev,
                        {
                            name: doc.data().player,
                            id: doc.id,
                        },
                    ]);
                });
            }
        };
        getTeam1Players();
    }, [team1]);
    useEffect(() => {
        setTeam2PlayerList([]);
        const getTeam2Players = async () => {
            const team2PlayersListRef = collection(db, collectionName.players);
            const team2PlayersListSnapshot = await getDocs(
                query(team2PlayersListRef, where("team", "==", team2))
            );
            if (!team2PlayersListSnapshot.empty) {
                team2PlayersListSnapshot.docs.forEach((doc) => {
                    setTeam2PlayerList((prev) => [
                        ...prev,
                        {
                            name: doc.data().player,
                            id: doc.id,
                        },
                    ]);
                });
            }
        };
        getTeam2Players();
    }, [team2]);

    const handleKAP = async (e) => {
        e.preventDefault();
        dispatch(setLoading(true));
        try {
            // check if own team

            if (currentUser.team !== team1 && currentUser.team !== team2)
                // eslint-disable-next-line
                throw { code: "kap/not-own-team" };

            // check if teams and players are the same
            // eslint-disable-next-line
            if (team1 === team2) throw { code: "kap/same-team" };

            // drop players from teams

            const draftRights = ["draft1", "draft2", "draft3"];

            const give1Name = give1.split(",")[0],
                give1Id = give1.split(",")[1],
                give2Name = give2.split(",")[0],
                give2Id = give2.split(",")[1];

            if (!draftRights.includes(give1Name)) {
                const player1Ref = doc(db, collectionName.players, give1Id);
                await updateDoc(player1Ref, { team: team2 });
            }

            if (!draftRights.includes(give2Name)) {
                const player2Ref = doc(db, collectionName.players, give2Id);
                await updateDoc(player2Ref, { team: team1 });
            }

            // write to "kaps" collection
            const kapsRef = collection(db, collectionName.kaps);
            await addDoc(kapsRef, {
                team1,
                give1: give1Name,
                team2,
                give2: give2Name,
                createdAt: serverTimestamp(),
            });
            dispatch(
                setFeedback({
                    severity: severity.success,
                    message: "KAP bildirimi başarılı.",
                })
            );
            redirect(0);
        } catch (e) {
            console.log(e.code);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(
                setFeedback({ severity: severity.error, message: errorMsg })
            );
        }
        dispatch(setLoading(false));
    };

    return (
        <Container component="main" maxWidth="md">
            <Typography variant="h4" textAlign="center">
                Kamu Aydınlatma Platformu
            </Typography>
            {tradeOpen && (
                <Box
                    component="form"
                    onSubmit={handleKAP}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Paper elevation={1} sx={{ mt: 2, p: 2, width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="team1">
                                        1. Takım
                                    </InputLabel>
                                    <Select
                                        name="team1"
                                        id="team1"
                                        label="1. Takım"
                                        defaultValue=""
                                        onChange={(e) =>
                                            setTeam1(e.target.value)
                                        }
                                        required
                                    >
                                        {teamList.map((team, index) => (
                                            <MenuItem
                                                key={`option-team1-${team}-${index}`}
                                                value={team}
                                            >
                                                {team}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="give1">
                                        1. Takımın Verdiği
                                    </InputLabel>
                                    <Select
                                        name="give1"
                                        id="give1"
                                        label="1. Takımın Verdiği"
                                        defaultValue=""
                                        onChange={(e) =>
                                            setGive1(e.target.value)
                                        }
                                        required
                                    >
                                        <MenuItem value="draft1">
                                            1. Tur Draft Hakkı
                                        </MenuItem>
                                        <MenuItem value="draft2">
                                            2. Tur Draft Hakkı
                                        </MenuItem>
                                        <MenuItem value="draft3">
                                            3. Tur Draft Hakkı
                                        </MenuItem>
                                        {team1PlayerList.map(
                                            (player, index) => (
                                                <MenuItem
                                                    key={`team1-${player.id}-${index}`}
                                                    value={`${player.name},${player.id}`}
                                                >
                                                    {player.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={1} sx={{ mt: 2, p: 2, width: "100%" }}>
                        <Grid container spacing={2}>
                            <Grid xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="team2">
                                        2. Takım
                                    </InputLabel>
                                    <Select
                                        name="team2"
                                        id="team2"
                                        label="2. Takım"
                                        defaultValue=""
                                        onChange={(e) =>
                                            setTeam2(e.target.value)
                                        }
                                        required
                                    >
                                        {teamList.map((team, index) => (
                                            <MenuItem
                                                key={`option-team2-${team}-${index}`}
                                                value={team}
                                            >
                                                {team}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="give2">
                                        2. Takımın Verdiği
                                    </InputLabel>
                                    <Select
                                        name="give2"
                                        id="give2"
                                        label="2. Takımın Verdiği"
                                        defaultValue=""
                                        onChange={(e) =>
                                            setGive2(e.target.value)
                                        }
                                        required
                                    >
                                        <MenuItem value="draft1">
                                            1. Tur Draft Hakkı
                                        </MenuItem>
                                        <MenuItem value="draft2">
                                            2. Tur Draft Hakkı
                                        </MenuItem>
                                        <MenuItem value="draft3">
                                            3. Tur Draft Hakkı
                                        </MenuItem>
                                        {team2PlayerList.map(
                                            (player, index) => (
                                                <MenuItem
                                                    key={`team2-${player.id}-${index}`}
                                                    value={`${player.name},${player.id}`}
                                                >
                                                    {player.name}
                                                </MenuItem>
                                            )
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                    >
                        Bildirim Yap
                    </Button>
                </Box>
            )}

            <Box sx={{ mt: 4, mb: 8 }}>
                {!!kapList?.length && (
                    <Typography variant="h5" textAlign="center" mb={2}>
                        Bildirimler
                    </Typography>
                )}
                <Grid2 container spacing={2}>
                    {kapList?.map((kap, index) => (
                        <Grid2 xs={12} sm={6} key={`kap-bildirim-${index}`}>
                            <KAPCard
                                key={`kap-card-${index}`}
                                team1={kap.team1}
                                player1={kap.give1}
                                team2={kap.team2}
                                player2={kap.give2}
                                date={formatDateDDMMYYYY(kap.createdAt)}
                            />
                        </Grid2>
                    ))}
                </Grid2>
            </Box>
        </Container>
    );
};

export default Kap;
