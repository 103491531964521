import React, { useState } from "react";
import {
    Box,
    Button,
    Container,
    Paper,
    TextField,
    Typography,
    Alert,
} from "@mui/material";
import { useAuth } from "../providers/AuthProvider";
import { db } from "../firebase/setup";
import { doc, setDoc } from "firebase/firestore";
import { collectionName, errorCodes } from "../constObjects";
import { setFeedback } from "../store/feedbackSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const ImportFixture = () => {
    const { currentUser } = useAuth();
    const [inputText, setInputText] = useState("");

    const redirect = useNavigate();
    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const parseFixtures = (input) => {
        const lines = input.split("\n");
        let week = 0;
        const fixtures = [];

        lines.forEach((line) => {
            if (line.toLowerCase().startsWith("hafta")) {
                week = parseInt(line.replace("hafta", "").trim());
            } else if (line.includes("-vs-")) {
                const [homeTeam, awayTeam] = line.split("-vs-");
                fixtures.push({
                    week,
                    match:
                        fixtures.filter((fixture) => fixture.week === week)
                            .length + 1,
                    homeTeam: homeTeam.trim(),
                    awayTeam: awayTeam.trim(),
                });
            }
        });

        return fixtures;
    };

    const handleSubmit = async (event) => {
        try {
            const formattedFixtures = parseFixtures(inputText);
            console.log(formattedFixtures);

            formattedFixtures.forEach(async (fixture) => {
                await setDoc(
                    doc(
                        db,
                        collectionName.fixtures,
                        `w${fixture.week}m${fixture.match}`
                    ),
                    fixture
                );
            });
            dispatch(
                setFeedback({
                    severity: "success",
                    message: "Fikstur eklendi.",
                })
            );
            redirect("/lig");
        } catch (e) {
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            const errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(setFeedback({ severity: "error", message: errorMsg }));
        }
    };

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Paper sx={{ width: "100%", p: 2, textAlign: "center" }}>
                    <Typography variant="h6" gutterBottom>
                        Fikstür Oluştur
                    </Typography>
                    <Alert severity="info" sx={{ mb: 2 }} variant="outlined">
                        Fikstürü aşağıdaki metin kutusuna şu şekilde girmemiz
                        lazım:
                        <Paper
                            variant="outlined"
                            component="div"
                            sx={{ padding: 2, textAlign: "left", marginY: 2 }}
                        >
                            hafta1
                            <br />
                            Haddini Bilbao-vs-Terimspor
                            <br />
                            Los Galacticos-vs-IronLegion
                            <br />
                            hafta2
                            <br />
                            Terimspor-vs-Los Galacticos
                            <br />
                            Iron Legion-vs-Haddini Bilbao
                        </Paper>
                    </Alert>
                    <Box component="form" onSubmit={handleSubmit}>
                        <TextField
                            label="Fikstürü buraya gir"
                            multiline
                            rows={10}
                            variant="outlined"
                            fullWidth
                            value={inputText}
                            onChange={handleInputChange}
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mt: 2 }}
                        >
                            Oluştur
                        </Button>
                    </Box>
                </Paper>
            </Box>
        </Container>
    );
};

export default ImportFixture;
