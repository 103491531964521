export const errorCodes = [
    { code: "auth/user-not-found", message: "Giriş bilgileriniz hatalı." },
    { code: "auth/wrong-password", message: "Giriş bilgileriniz hatalı." },
    {
        code: "auth/email-already-in-use",
        message: "Bu e-posta adresi zaten kullanılıyor.",
    },
    { code: "auth/invalid-email", message: "Geçersiz e-posta adresi." },
    { code: "auth/weak-password", message: "Parolanız çok zayıf." },
    { code: "user/team-aldready-in-use", message: "Bu takım zaten mevcut." },
    {
        code: "user/manager-aldready-in-use",
        message: "Bu teknik direktör zaten mevcut.",
    },
    {
        code: "user/president-aldready-in-use",
        message: "Bu kulüp başkanı zaten mevcut.",
    },
    {
        code: "draft/player-already-drafted",
        message: "Bu oyuncu zaten draft edilmiş.",
    },
    {
        code: "failed-precondition",
        message: "Veritabanını sorgularken bir hata oluştu.",
    },
    {
        code: "user/incorrect-secret",
        message: "Güvenlik sorusuna verdiğiniz cevap yanlış.",
    },
    {
        code: "kap/same-team",
        message: "KAP bildirimi yapan iki takım aynı olamaz.",
    },
    {
        code: "kap/not-own-team",
        message: "Başka takım adına KAP bildirimi yapılamaz.",
    },
    {
        code: "results/duplicate-player",
        message: "Aynı oyuncu birden fazla kez yazılamaz.",
    },
    {
        code: "invalid-argument",
        message: "Geçersiz argüman. Baferin ile iletişime geçiniz.",
    },
    {
        code: "admin/not-admin",
        message: "Bu işlemi yapabilmek için admin yetkisine sahip olmalısınız.",
    },
    {
        code: "permission-denied",
        message: "Erişim engellendi. Baferin ile iletişime geçiniz.",
    },
];

export const collectionName = {
    control: "control",
    secrets: "secrets",
    users: "users",
    players: "players2025",
    results: "results2025",
    standings: "standings2025",
    drafts: "drafts2025",
    fixtures: "fixtures2025",
    kaps: "kaps2025",
};

export const severity = {
    success: "success",
    error: "error",
    info: "info",
    warning: "warning",
};

export const formatDateDDMMYYYY = (createdAt) => {
    const date = new Date(createdAt.seconds * 1000);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};
