import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
} from "@mui/material";
import React from "react";

const TeamCard = ({ team, isCardAction }) => {
    return (
        <Card
            sx={{
                width: 350,
                height: 350,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                textAlign: "center",
            }}
        >
            <CardMedia
                component="img"
                sx={{
                    height: "auto",
                    width: "100%",
                    maxHeight: 100,
                    objectFit: "contain",
                    mt: 2,
                    mx: "auto",
                }}
                image={team.logoUrl}
                alt={team.team}
            />
            <CardContent
                sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
            >
                <Typography variant="h4">{team.team}</Typography>
                <Typography variant="h6">{team.manager}</Typography>
                <Typography variant="h6">{team.president}</Typography>
            </CardContent>
            {isCardAction && (
                <CardActions sx={{ justifyContent: "center" }}>
                    <Button
                        href={`/profil/${team.teamId}`}
                        variant="outlined"
                        color="secondary"
                    >
                        Kadroyu gör
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default TeamCard;
