import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography,
    Box,
    TextField,
} from "@mui/material";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { db } from "../firebase/setup";
import { collectionName, errorCodes, severity } from "../constObjects";
import { useDispatch } from "react-redux";
import { setFeedback, setLoading } from "../store/feedbackSlice";

const ProfileCard = ({ team, isEditable }) => {
    console.log("team", team);
    const [manager, setManager] = useState(team.manager);
    const [president, setPresident] = useState(team.president);

    const dispatch = useDispatch();

    useEffect(() => {
        setManager(team.manager);
        setPresident(team.president);
    }, [team]);

    const handleEditProfile = async (e) => {
        e.preventDefault();
        console.log("handleEditProfile");
        console.log("team.manager", team.manager);
        console.log("team.president", team.president);
        try {
            dispatch(setLoading(true));

            const usersRef = doc(db, collectionName.users, team.uid);
            await updateDoc(usersRef, {
                manager,
                president,
                updatedAt: serverTimestamp(),
            });

            dispatch(
                setFeedback({
                    severity: severity.success,
                    message: `Profile düzenlendi.`,
                })
            );
        } catch (e) {
            console.log("e", e);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(
                setFeedback({ severity: severity.error, message: errorMsg })
            );
        }
        dispatch(setLoading(false));
    };

    return (
        <Card
            component="form"
            sx={{
                maxWidth: 600,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 2,
            }}
            onSubmit={handleEditProfile}
        >
            <Box component="div" display="flex" alignItems="center" gap={2}>
                <CardMedia
                    component="img"
                    sx={{ height: "100px", width: "100px" }}
                    image={team.logoUrl}
                    alt={team.team}
                    aria-description={`${team.team} logosu`}
                />
                <CardContent
                    sx={{
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 2,
                    }}
                >
                    <Typography variant="h4">{team.team}</Typography>
                    <TextField
                        label="Teknik Direktör"
                        // key={`manager-${editProfileData.manager}`}
                        // name={`manager`}
                        // id={`manager`}
                        value={manager}
                        onChange={(e) => setManager(e.target.value)}
                        required
                        fullWidth
                        variant="standard"
                        disabled={!isEditable}
                    />
                    <TextField
                        label="Kulüp Başkanı"
                        // key={`president-${editProfileData.president}`}
                        // name={`president`}
                        // id={`president`}
                        value={president}
                        onChange={(e) => setPresident(e.target.value)}
                        required
                        fullWidth
                        variant="standard"
                        disabled={!isEditable}
                    />
                </CardContent>
            </Box>
            {isEditable && (
                <CardActions sx={{ justifyContent: "center" }}>
                    <Button variant="outlined" color="secondary" type="submit">
                        Düzenle
                    </Button>
                </CardActions>
            )}
        </Card>
    );
};

export default ProfileCard;
