import {
    createContext,
    useContext,
    useState,
    useMemo,
    forwardRef,
} from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";

// Create context
const ColorModeContext = createContext();

// Create a hook to use ColorModeContext in our components
export const useColorMode = () => {
    return useContext(ColorModeContext);
};

// Create ColorModeProvider to wrap our app inside
// and distribute ColorModeContext
export const ColorModeProvider = ({ children }) => {
    // We'll be storing color-mode value in the local storage
    // So let's fetch that value
    const [colorMode, toggleColorMode] = useState(
        localStorage.getItem("color-mode")
    );

    // Context value object to be provided
    const value = useMemo(
        () => ({
            // toggleColorMode method toggles `color-mode` value
            // in local storage and colorMode state between `dark` and `light`
            toggleColorMode: () => {
                if (localStorage.getItem("color-mode") === "light") {
                    localStorage.setItem("color-mode", "dark");
                } else {
                    localStorage.setItem("color-mode", "light");
                }
                toggleColorMode((prev) =>
                    prev === "light" ? "dark" : "light"
                );
            },
            colorMode,
        }),
        // Make sure colorMode is in the dependency array
        // Otherwise, colorMode context value won't be updating
        // although colorMode state value changes.
        // We see this behavior because useMemo hook caches
        // values until the values in the dependency array changes
        [colorMode]
    );

    // Theme object to be provided
    const theme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: colorMode, // Set mode property
                    ...(colorMode === "dark"
                        ? // If colorMode is `dark`
                          {
                              // palette values for dark mode
                              primary: {
                                  main: "#b0bec5",
                              },
                              secondary: {
                                  main: "#9c27b0",
                              },
                          }
                        : // If colorMode is `light`
                          {
                              // palette values for light mode
                              primary: {
                                  main: "#CFD8DC",
                              },
                              secondary: {
                                  main: "#01579b",
                              },
                              background: {
                                  default: "#f5f5f5",
                                  paper: "#eceff1",
                              },
                          }),
                },
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                textTransform: "none",
                                width: "fit-content",
                            },
                        },
                    },
                    MuiLink: {
                        defaultProps: {
                            component: LinkBehaviorReload,
                        },
                    },
                    MuiButtonBase: {
                        defaultProps: {
                            LinkComponent: LinkBehaviorReload,
                        },
                    },
                    MuiMenuItem: {
                        defaultProps: {
                            LinkComponent: LinkBehaviorReload,
                        },
                    },
                    MuiTypography: {
                        defaultProps: {
                            color: colorMode === "dark" ? "#fff" : "#212121",
                        },
                    },
                },
            }),
        // Remember to add colorMode to dependency array
        // Otherwise, palette.mode property wont be updating
        // resulting in unchanged theme
        [colorMode]
    );

    // Return provider
    return (
        // We wrap our own context provider around MUI's ThemeProvider
        <ColorModeContext.Provider value={value}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    );
};

const LinkBehavior = forwardRef((props, ref) => {
    const { href, ...other } = props;
    return <RouterLink ref={ref} to={href} {...other} />;
});
const LinkBehaviorReload = forwardRef((props, ref) => {
    const { href, ...other } = props;
    return <RouterLink ref={ref} to={href} reloadDocument={true} {...other} />;
});
LinkBehavior.propTypes = {
    href: PropTypes.oneOfType([
        PropTypes.shape({
            hash: PropTypes.string,
            pathname: PropTypes.string,
            search: PropTypes.string,
        }),
        PropTypes.string,
    ]).isRequired,
};
