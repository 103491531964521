import { db } from "../firebase/setup";
import { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { collectionName } from "../constObjects";
import TeamCard from "../components/TeamCard";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";

const Teams = () => {
    const [teamList, setTeamList] = useState([]);

    useEffect(() => {
        const getTeams = async () => {
            const usersRef = collection(db, collectionName.users);
            const teamSnapshot = await getDocs(usersRef);
            if (!teamSnapshot.empty) {
                teamSnapshot.docs.forEach((doc) => {
                    setTeamList((prev) => [
                        ...prev,
                        { ...doc.data(), teamId: doc.id },
                    ]);
                });
            }
        };

        getTeams();
        //eslint-disable-next-line
    }, []);

    return (
        <Box
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
            mx="auto"
            mb={8}
        >
            <Grid container spacing={2} justifyContent="center">
                {teamList
                    .filter((team) => team.active)
                    .map((team, index) => (
                        <Grid
                            key={`team-${index}`}
                            xs={12}
                            sm={6}
                            display="flex"
                            justifyContent="center"
                        >
                            <TeamCard team={team} isCardAction={true} />
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};

export default Teams;
