import React from "react";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Grid,
    Divider,
} from "@mui/material";

const KAPCard = ({ team1, player1, team2, player2, date }) => (
    <Card
        sx={{
            p: 2,
        }}
    >
        <CardContent>
            <Typography
                variant="subtitle2"
                color="text.secondary"
                textAlign="center"
                gutterBottom
            >
                {date}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={5}>
                    <Box textAlign="center">
                        <Typography variant="subtitle1" color="text.secondary">
                            1. Takım
                        </Typography>
                        <Typography variant="h6">{team1}</Typography>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{ mt: 1 }}
                        >
                            Takas edilen
                        </Typography>
                        <Typography variant="body1">{player1}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Box
                        textAlign="center"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                    >
                        <Typography variant="h6">↔️</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box textAlign="center">
                        <Typography variant="subtitle1" color="text.secondary">
                            2. Takım
                        </Typography>
                        <Typography variant="h6">{team2}</Typography>
                        <Typography
                            variant="subtitle2"
                            color="text.secondary"
                            sx={{ mt: 1 }}
                        >
                            Takas edilen
                        </Typography>
                        <Typography variant="body1">{player2}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default KAPCard;
