import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
    severity: "",
    message: "",
};

const feedbackSlice = createSlice({
    name: "feedback",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setFeedback: (state, action) => {
            const { severity, message } = action.payload;
            state.severity = severity;
            state.message = message;
        },
    },
});

export const { setLoading, setFeedback } = feedbackSlice.actions;
export default feedbackSlice.reducer;
