import { useEffect, useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { errorCodes } from "../constObjects";
import { useDispatch } from "react-redux";
import { setFeedback, setLoading } from "../store/feedbackSlice";
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Link,
} from "@mui/material";

const SignIn = () => {
    // STATES
    const [signInData, setSignInData] = useState({
        email: "",
        password: "",
    });
    // HOOKS AND CONTEXTS
    const { signIn, currentUser } = useAuth();
    const redirect = useNavigate();
    const dispatch = useDispatch();

    // User already signed in ? => redirect
    useEffect(() => {
        currentUser && redirect("/");
        //eslint-disable-next-line
    }, [currentUser]);

    // Send sign in data
    const handleSignIn = async (e) => {
        e.preventDefault();
        try {
            dispatch(setLoading(true));
            // sign in user
            await signIn(signInData.email, signInData.password);
            dispatch(
                setFeedback({ severity: "success", message: "Giriş başarılı." })
            );
        } catch (e) {
            console.log(e.code);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            const errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(setFeedback({ severity: "error", message: errorMsg }));
        }
        dispatch(setLoading(false));
    };

    const handleChange = (e) => {
        e.preventDefault();
        setSignInData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };
    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Fantazi Liğ'e Hoşgeldiniz!
                </Typography>
                <Box
                    component="form"
                    sx={{
                        width: "100%", // Fix IE 11 issue.
                        mt: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    onSubmit={handleSignIn}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label="E-posta"
                        autoComplete="email"
                        onChange={handleChange}
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Şifre"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Giriş yap
                    </Button>
                </Box>
                <Link href="/kayit" variant="body2">
                    {"Halâ Fantazi Liğ üyesi değil misin? Kayıt ol."}
                </Link>
            </Box>
        </Container>
    );
};

export default SignIn;
