import { useParams } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import {
    collection,
    getDoc,
    getDocs,
    query,
    where,
    doc,
    serverTimestamp,
    updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { db } from "../firebase/setup";
import { collectionName, errorCodes, severity } from "../constObjects";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setFeedback, setLoading } from "../store/feedbackSlice";

const eleven = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const EditResult = () => {
    const { currentUser } = useAuth();
    const { wm } = useParams();
    const [homeTeam, setHomeTeam] = useState("");
    const [awayTeam, setAwayTeam] = useState("");
    const [homeTeamList, setHomeTeamList] = useState([]);
    const [awayTeamList, setAwayTeamList] = useState([]);
    const [homeTeamRoster, setHomeTeamRoster] = useState([]);
    const [homeTeamCpt, setHomeTeamCpt] = useState("");
    const [awayTeamCpt, setAwayTeamCpt] = useState("");
    const [homeTeamSs, setHomeTeamSs] = useState([]);
    const [homeTeamSp, setHomeTeamSp] = useState([]);
    const [awayTeamRoster, setAwayTeamRoster] = useState([]);
    const [awayTeamSs, setAwayTeamSs] = useState([]);
    const [awayTeamSp, setAwayTeamSp] = useState([]);
    // const redirect = useNavigate();
    const dispatch = useDispatch();

    const match = wm.split("m")[1];
    const week = wm.split("m")[0].split("w")[1];

    useEffect(() => {
        const getMatchResult = async () => {
            dispatch(setLoading(true));

            try {
                const resultRef = doc(db, collectionName.results, wm);
                const resultSnapshot = await getDoc(resultRef);
                if (resultSnapshot.exists) {
                    console.log("resultSnapshot.data()", resultSnapshot.data());
                    setHomeTeam(resultSnapshot.data().homeTeam);
                    setHomeTeamRoster(resultSnapshot.data().homeTeamRoster);
                    setHomeTeamCpt(resultSnapshot.data().homeTeamCpt);
                    setHomeTeamSs(resultSnapshot.data().homeTeamSs);
                    setHomeTeamSp(resultSnapshot.data().homeTeamSp);
                    setAwayTeam(resultSnapshot.data().awayTeam);
                    setAwayTeamRoster(resultSnapshot.data().awayTeamRoster);
                    setAwayTeamCpt(resultSnapshot.data().awayTeamCpt);
                    setAwayTeamSs(resultSnapshot.data().awayTeamSs);
                    setAwayTeamSp(resultSnapshot.data().awayTeamSp);
                }
            } catch (e) {
                console.log(e.code);
                const errorObj = errorCodes.filter(
                    (error) => error.code === e.code
                );
                let errorMsg = Object.keys(errorObj).length
                    ? errorObj[0].message || ""
                    : "Tanımlanmamış hata.";
                dispatch(
                    setFeedback({ severity: severity.error, message: errorMsg })
                );
            }
            dispatch(setLoading(false));
        };
        getMatchResult();
    }, []);
    // get home team list
    useEffect(() => {
        setHomeTeamList([]);
        const getHomeTeamPlayers = async () => {
            try {
                let tempHomeTeamList = [];
                const homeTeamPlayersRef = collection(
                    db,
                    collectionName.players
                );
                const homeTeamPlayersSnapshot = await getDocs(
                    query(homeTeamPlayersRef, where("team", "==", homeTeam))
                );
                if (!homeTeamPlayersSnapshot.empty) {
                    homeTeamPlayersSnapshot.docs.forEach((doc) => {
                        tempHomeTeamList.push({
                            name: doc.data().player,
                            id: doc.id,
                        });
                    });
                }
                tempHomeTeamList.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                setHomeTeamList(tempHomeTeamList);
            } catch (e) {
                console.log(e.code);
                const errorObj = errorCodes.filter(
                    (error) => error.code === e.code
                );
                let errorMsg = Object.keys(errorObj).length
                    ? errorObj[0].message || ""
                    : "Tanımlanmamış hata.";
                dispatch(
                    setFeedback({ severity: severity.error, message: errorMsg })
                );
            }
            dispatch(setLoading(false));
        };
        getHomeTeamPlayers();
    }, [homeTeam]);

    // get away team list
    useEffect(() => {
        setAwayTeamList([]);
        const getAwayTeamPlayers = async () => {
            dispatch(setLoading(true));
            try {
                let tempAwayTeamList = [];
                const awayTeamPlayersRef = collection(
                    db,
                    collectionName.players
                );
                const awayTeamPlayersSnapshot = await getDocs(
                    query(awayTeamPlayersRef, where("team", "==", awayTeam))
                );
                if (!awayTeamPlayersSnapshot.empty) {
                    awayTeamPlayersSnapshot.docs.forEach((doc) => {
                        tempAwayTeamList.push({
                            name: doc.data().player,
                            id: doc.id,
                        });
                    });
                }
                tempAwayTeamList.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                setAwayTeamList(tempAwayTeamList);
            } catch (e) {
                console.log(e.code);
                const errorObj = errorCodes.filter(
                    (error) => error.code === e.code
                );
                let errorMsg = Object.keys(errorObj).length
                    ? errorObj[0].message || ""
                    : "Tanımlanmamış hata.";
                dispatch(
                    setFeedback({ severity: severity.error, message: errorMsg })
                );
            }
            dispatch(setLoading(false));
        };
        getAwayTeamPlayers();
    }, [awayTeam]);

    const handleUpdateResult = async (e) => {
        e.preventDefault();
        try {
            dispatch(setLoading(true));
            // eslint-disable-next-line
            if (!currentUser.admin) throw { code: "admin/not-admin" };
            // get standings
            // delete corresponding index (week)
            let homeTeamData, awayTeamData;
            const homeTeamStandingRef = doc(
                db,
                collectionName.standings,
                homeTeam
            );
            const homeTeamStandingSnap = await getDoc(homeTeamStandingRef);
            if (homeTeamStandingSnap.exists) {
                homeTeamData = homeTeamStandingSnap.data();
                homeTeamData.draws.splice(week - 1, 1);
                homeTeamData.forms.splice(week - 1, 1);
                homeTeamData.losses.splice(week - 1, 1);
                homeTeamData.points.splice(week - 1, 1);
                homeTeamData.sps.splice(week - 1, 1);
                homeTeamData.sss.splice(week - 1, 1);
                homeTeamData.wins.splice(week - 1, 1);
            }
            await updateDoc(homeTeamStandingRef, homeTeamData);
            const awayTeamStandingRef = doc(
                db,
                collectionName.standings,
                awayTeam
            );
            const awayTeamStandingSnap = await getDoc(awayTeamStandingRef);
            if (awayTeamStandingSnap.exists) {
                awayTeamData = awayTeamStandingSnap.data();
                awayTeamData.draws.splice(week - 1, 1);
                awayTeamData.forms.splice(week - 1, 1);
                awayTeamData.losses.splice(week - 1, 1);
                awayTeamData.points.splice(week - 1, 1);
                awayTeamData.sps.splice(week - 1, 1);
                awayTeamData.sss.splice(week - 1, 1);
                awayTeamData.wins.splice(week - 1, 1);
            }
            await updateDoc(awayTeamStandingRef, awayTeamData);

            // find cpt indices
            const homeCptInd = homeTeamRoster.indexOf(homeTeamCpt);
            const awayCptInd = awayTeamRoster.indexOf(awayTeamCpt);

            // sum up sofascores and skill points
            const homeTeamTotalSs = homeTeamSs.reduce((prev, cur, ind) => {
                if (ind === homeCptInd) cur = cur * 2;
                return prev + parseFloat(cur);
            }, 0);
            const homeTeamTotalSp = homeTeamSp.reduce(
                (prev, cur) => prev + parseInt(cur),
                0
            );
            const awayTeamTotalSs = awayTeamSs.reduce((prev, cur, ind) => {
                if (ind === awayCptInd) cur = cur * 2;
                return prev + parseFloat(cur);
            }, 0);
            const awayTeamTotalSp = awayTeamSp.reduce(
                (prev, cur) => prev + parseInt(cur),
                0
            );

            // update results
            const updatedResult = {
                week,
                match,
                homeTeam,
                homeTeamRoster,
                homeTeamCpt,
                homeTeamSs,
                homeTeamTotalSs,
                homeTeamSp,
                homeTeamTotalSp,
                awayTeam,
                awayTeamRoster,
                awayTeamCpt,
                awayTeamSs,
                awayTeamTotalSs,
                awayTeamSp,
                awayTeamTotalSp,
                createdAt: serverTimestamp(),
            };
            await updateDoc(doc(db, collectionName.results, wm), updatedResult);

            // re-calculate winner loser and post to standings

            if (homeTeamTotalSs > awayTeamTotalSs) {
                // home wins
                homeTeamData.forms[week - 1] = "w";
                homeTeamData.wins[week - 1] = 1;
                homeTeamData.draws[week - 1] = 0;
                homeTeamData.losses[week - 1] = 0;
                homeTeamData.points[week - 1] = 3;
                homeTeamData.sss[week - 1] = parseFloat(
                    homeTeamTotalSs.toFixed(1)
                );
                homeTeamData.sps[week - 1] = homeTeamTotalSp;
                // away loses
                awayTeamData.forms[week - 1] = "l";
                awayTeamData.wins[week - 1] = 0;
                awayTeamData.draws[week - 1] = 0;
                awayTeamData.losses[week - 1] = 1;
                awayTeamData.points[week - 1] = 0;
                awayTeamData.sss[week - 1] = parseFloat(
                    awayTeamTotalSs.toFixed(1)
                );
                awayTeamData.sps[week - 1] = awayTeamTotalSp;
            } else if (awayTeamTotalSs > homeTeamTotalSs) {
                // away wins
                awayTeamData.forms[week - 1] = "w";
                awayTeamData.wins[week - 1] = 1;
                awayTeamData.draws[week - 1] = 0;
                awayTeamData.losses[week - 1] = 0;
                awayTeamData.points[week - 1] = 3;
                awayTeamData.sss[week - 1] = parseFloat(
                    awayTeamTotalSs.toFixed(1)
                );
                awayTeamData.sps[week - 1] = awayTeamTotalSp;
                // home loses
                homeTeamData.forms[week - 1] = "l";
                homeTeamData.wins[week - 1] = 0;
                homeTeamData.draws[week - 1] = 0;
                homeTeamData.losses[week - 1] = 1;
                homeTeamData.points[week - 1] = 0;
                homeTeamData.sss[week - 1] = parseFloat(
                    homeTeamTotalSs.toFixed(1)
                );
                homeTeamData.sps[week - 1] = homeTeamTotalSp;
            } else {
                // away draws
                awayTeamData.forms[week - 1] = "d";
                awayTeamData.wins[week - 1] = 0;
                awayTeamData.draws[week - 1] = 1;
                awayTeamData.losses[week - 1] = 0;
                awayTeamData.points[week - 1] = 1;
                awayTeamData.sss[week - 1] = parseFloat(
                    awayTeamTotalSs.toFixed(1)
                );
                awayTeamData.sps[week - 1] = awayTeamTotalSp;
                // home draws
                homeTeamData.forms[week - 1] = "d";
                homeTeamData.wins[week - 1] = 0;
                homeTeamData.draws[week - 1] = 1;
                homeTeamData.losses[week - 1] = 0;
                homeTeamData.points[week - 1] = 1;
                homeTeamData.sss[week - 1] = parseFloat(
                    homeTeamTotalSs.toFixed(1)
                );
                homeTeamData.sps[week - 1] = homeTeamTotalSp;
            }

            // update home team standing
            await updateDoc(homeTeamStandingRef, {
                points: homeTeamData.points,
                forms: homeTeamData.forms,
                wins: homeTeamData.wins,
                draws: homeTeamData.draws,
                losses: homeTeamData.losses,
                sss: homeTeamData.sss,
                sps: homeTeamData.sps,
                lastUpdated: serverTimestamp(),
            });
            // update away team standing
            await updateDoc(awayTeamStandingRef, {
                points: awayTeamData.points,
                forms: awayTeamData.forms,
                wins: awayTeamData.wins,
                draws: awayTeamData.draws,
                losses: awayTeamData.losses,
                sss: awayTeamData.sss,
                sps: awayTeamData.sps,
                lastUpdated: serverTimestamp(),
            });

            dispatch(
                setFeedback({
                    severity: severity.success,
                    message: "Maç sonucu güncellendi.",
                })
            );
        } catch (e) {
            console.log(e.code);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(
                setFeedback({ severity: severity.error, message: errorMsg })
            );
        }
        dispatch(setLoading(false));
    };

    return (
        <Box
            component="form"
            onSubmit={handleUpdateResult}
            sx={{ width: "100%", textAlign: "center", mb: 8 }}
        >
            <Typography variant="h4" sx={{ textAlign: "center", mb: 4 }}>
                Maç Sonucu Düzenle
            </Typography>

            <Paper elevation={1} sx={{ my: 4, p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Box component="div">
                            <Typography variant="caption">
                                Ev Sahibi Takım
                            </Typography>
                            <Typography variant="body1">{homeTeam}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box component="div">
                            <Typography variant="caption">
                                Deplasman Takım
                            </Typography>
                            <Typography variant="body1">{awayTeam}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box component="div">
                            <Typography variant="caption">Hafta/Maç</Typography>
                            <Typography variant="body1">
                                {week}/{match}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {/* home team */}
            <Paper elevation={1} sx={{ my: 4, p: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Ev Sahibi
                </Typography>

                {eleven.map((index) => (
                    <Grid
                        container
                        spacing={2}
                        key={`home-${index}`}
                        sx={{ mt: 1 }}
                    >
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    Ev Sahibi Oyuncu {index + 1}
                                </InputLabel>
                                <Select
                                    name={`homePlayer-${index}`}
                                    id={`homePlayer-${index}`}
                                    value={homeTeamRoster[index] || ""}
                                    required
                                    onChange={(e) => {
                                        let tempHomeTeamRoster = [
                                            ...homeTeamRoster,
                                        ];
                                        let player = tempHomeTeamRoster[index];
                                        if (
                                            tempHomeTeamRoster.includes(
                                                e.target.value
                                            )
                                        ) {
                                            dispatch(
                                                setFeedback({
                                                    severity: severity.error,
                                                    message:
                                                        "Bu oyuncu zaten seçilmiş.",
                                                })
                                            );
                                            return;
                                        }
                                        player = e.target.value;
                                        tempHomeTeamRoster[index] = player;
                                        setHomeTeamRoster(tempHomeTeamRoster);
                                    }}
                                >
                                    {homeTeamList.map((player) => (
                                        <MenuItem
                                            key={`option-${player.name}`}
                                            value={player.name}
                                        >
                                            {player.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SS"
                                inputProps={{ min: 0, max: 10, step: 0.1 }}
                                value={parseFloat(homeTeamSs[index])}
                                required
                                name={`homeSs-${index}`}
                                id={`homeSs-${index}`}
                                onChange={(e) => {
                                    let tempHomeTeamSs = [...homeTeamSs];
                                    let ss = tempHomeTeamSs[index];
                                    ss = e.target.value;
                                    tempHomeTeamSs[index] = ss;
                                    setHomeTeamSs(tempHomeTeamSs);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SP"
                                inputProps={{ min: 0, max: 15, step: 1 }}
                                name={`homeSp-${index}`}
                                id={`homeSp-${index}`}
                                value={parseFloat(homeTeamSp[index])}
                                required
                                onChange={(e) => {
                                    let tempHomeTeamSp = [...homeTeamSp];
                                    let sp = tempHomeTeamSp[index];
                                    sp = e.target.value;
                                    tempHomeTeamSp[index] = sp;
                                    setHomeTeamSp(tempHomeTeamSp);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={0}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                    </Grid>
                ))}
                <FormControl fullWidth>
                    <InputLabel>Ev Sahibi Takım Kaptanı</InputLabel>
                    <Select
                        name="homePlayerCpt"
                        id="homePlayerCpt"
                        value={homeTeamCpt || ""}
                        required
                        onChange={(e) => setHomeTeamCpt(e.target.value)}
                    >
                        {homeTeamList.map((player) => (
                            <MenuItem
                                key={`option-${player.name}`}
                                value={player.name}
                            >
                                {player.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>

            {/* away team */}
            <Paper elevation={1} sx={{ my: 4, p: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Deplasman
                </Typography>

                {eleven.map((index) => (
                    <Grid
                        container
                        spacing={2}
                        key={`away-${index}`}
                        sx={{ mt: 1 }}
                    >
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    Deplasman Oyuncu {index + 1}
                                </InputLabel>
                                <Select
                                    name={`awayPlayer-${index}`}
                                    id={`awayPlayer-${index}`}
                                    value={awayTeamRoster[index] || ""}
                                    onChange={(e) => {
                                        let tempAwayTeamRoster = [
                                            ...awayTeamRoster,
                                        ];
                                        let player = tempAwayTeamRoster[index];
                                        if (
                                            tempAwayTeamRoster.includes(
                                                e.target.value
                                            )
                                        ) {
                                            dispatch(
                                                setFeedback({
                                                    severity: severity.error,
                                                    message:
                                                        "Bu oyuncu zaten seçilmiş.",
                                                })
                                            );
                                            return;
                                        }
                                        player = e.target.value;
                                        tempAwayTeamRoster[index] = player;
                                        setAwayTeamRoster(tempAwayTeamRoster);
                                    }}
                                >
                                    {awayTeamList.map((player) => (
                                        <MenuItem
                                            key={`option-${player.name}`}
                                            value={player.name}
                                        >
                                            {player.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SS"
                                inputProps={{ min: 0, max: 10, step: 0.1 }}
                                name={`awaySs-${index}`}
                                id={`awaySs-${index}`}
                                value={parseFloat(awayTeamSs[index])}
                                required
                                onChange={(e) => {
                                    let tempAwayTeamSs = [...awayTeamSs];
                                    let ss = tempAwayTeamSs[index];
                                    ss = e.target.value;
                                    tempAwayTeamSs[index] = ss;
                                    setAwayTeamSs(tempAwayTeamSs);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SP"
                                inputProps={{ min: 0, max: 15, step: 1 }}
                                name={`awaySp-${index}`}
                                id={`awaySp-${index}`}
                                value={parseFloat(awayTeamSp[index])}
                                required
                                onChange={(e) => {
                                    let tempAwayTeamSp = [...awayTeamSp];
                                    let sp = tempAwayTeamSp[index];
                                    sp = e.target.value;
                                    tempAwayTeamSp[index] = sp;
                                    setAwayTeamSp(tempAwayTeamSp);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={0}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                    </Grid>
                ))}

                <FormControl fullWidth>
                    <InputLabel>Deplasman Takımı Kaptanı</InputLabel>
                    <Select
                        name="awayPlayerCpt"
                        id="awayPlayerCpt"
                        value={awayTeamCpt || ""}
                        onChange={(e) => setAwayTeamCpt(e.target.value)}
                    >
                        {awayTeamList.map((player) => (
                            <MenuItem
                                key={`option-${player.name}`}
                                value={player.name}
                            >
                                {player.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ alignSelf: "center" }}
            >
                Güncelle
            </Button>
        </Box>

        // <div className="bg-lightBackground dark:bg-darkBackground w-5/6 sm:w-2/3 my-10">
        //   {error && (
        //     <div
        //       className="w-full p-4 mb-4 text-sm bg-lightError rounded-lg dark:bg-darkError text-lightOnError dark:text-darkOnError"
        //       role="alert"
        //     >
        //       <span className="font-bold">Hata!</span> {error}
        //     </div>
        //   )}
        //   <form
        //     className="flex flex-col space-y-4 w-full"
        //     onSubmit={handleUpdateResult}
        //   >
        //     <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-4 sm:space-y-0 my-4">
        //       <div className="sm:w-1/4">
        //         <label
        //           htmlFor="homeTeam"
        //           className="block mb-2 text-sm font-medium text-lightOnSurface dark:text-darkOnSurface"
        //         >
        //           Ev Sahibi
        //         </label>
        //         <input
        //           type="text"
        //           name="homeTeam"
        //           id="homeTeam"
        //           className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //           defaultValue={homeTeam}
        //           disabled
        //         />
        //       </div>
        //       <div className="sm:w-1/4">
        //         <label
        //           htmlFor="awayTeam"
        //           className="block mb-2 text-sm font-medium text-lightOnSurface dark:text-darkOnSurface"
        //         >
        //           Deplasman
        //         </label>
        //         <input
        //           type="text"
        //           name="awayTeam"
        //           id="awayTeam"
        //           className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //           defaultValue={awayTeam}
        //           disabled
        //         />
        //       </div>
        //       <div className="sm:w-1/4">
        //         <label
        //           htmlFor="week"
        //           className="block mb-2 text-sm font-medium text-lightOnSurface dark:text-darkOnSurface"
        //         >
        //           Hafta
        //         </label>
        //         <input
        //           type="text"
        //           name="week"
        //           id="week"
        //           className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //           defaultValue={week}
        //           disabled
        //         />
        //       </div>
        //       <div className="sm:w-1/4">
        //         <label
        //           htmlFor="match"
        //           className="block mb-2 text-sm font-medium text-lightOnSurface dark:text-darkOnSurface"
        //         >
        //           Maç
        //         </label>
        //         <input
        //           type="text"
        //           name="match"
        //           id="match"
        //           className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //           defaultValue={match}
        //           disabled
        //         />
        //       </div>
        //     </div>
        //     {/* home team */}
        //     <div className="flex flex-col space-y-2 w-full my-4">
        //       <h1 className="text-lightOnSurface dark:text-darkOnSurface text-center text-2xl uppercase font-semibold underline underline-offset-4">
        //         Ev Sahibi
        //       </h1>
        //       <div className="flex font-bold uppercase text-lightOnSurface dark:text-darkOnSurface">
        //         <div className="w-6/12 text-center">Oyuncu</div>
        //         <div className="w-3/12 text-center">SS</div>
        //         <div className="w-3/12 text-center">SP</div>
        //       </div>
        //       {eleven.map((index) => (
        //         <div
        //           key={`home-${index}`}
        //           className="flex justify-between space-x-2 sm:space-x-4 w-full"
        //         >
        //           <div className="w-6/12">
        //             <select
        //               name={`homePlayer-${index}`}
        //               id={`homePlayer-${index}`}
        //               className=" bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //               value={homeTeamRoster[index]}
        //               onChange={(e) => {
        //                 setError("");
        //                 let tempHomeTeamRoster = [...homeTeamRoster];
        //                 let player = tempHomeTeamRoster[index];
        //                 if (tempHomeTeamRoster.includes(e.target.value)) {
        //                   setError("Bu oyuncu zaten seçilmiş");
        //                 }
        //                 player = e.target.value;
        //                 tempHomeTeamRoster[index] = player;
        //                 setHomeTeamRoster(tempHomeTeamRoster);
        //               }}
        // disabled
        //      required
        //             >
        //               {homeTeamList.map((player) => (
        //                 <option key={`option-${player.name}`} value={player.name}>
        //                   {player.name}
        //                 </option>
        //               ))}
        //             </select>
        //           </div>
        //           <div className="w-3/12">
        //             <input
        //               type="number"
        //               min={0}
        //               max={10}
        //               step={0.1}
        //               name={`homeSs-${index}`}
        //               id={`homeSs-${index}`}
        //               className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //               defaultValue={homeTeamSs[index]}
        //               onChange={(e) => {
        //                 let tempHomeTeamSs = [...homeTeamSs];
        //                 let ss = tempHomeTeamSs[index];
        //                 ss = e.target.value;
        //                 tempHomeTeamSs[index] = ss;
        //                 setHomeTeamSs(tempHomeTeamSs);
        //               }}
        //             />
        //           </div>
        //           <div className="w-3/12">
        //             <input
        //               type="number"
        //               min={0}
        //               max={15}
        //               step={1}
        //               name={`homeSp-${index}`}
        //               id={`homeSp-${index}`}
        //               className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //               defaultValue={homeTeamSp[index]}
        //               onChange={(e) => {
        //                 let tempHomeTeamSp = [...homeTeamSp];
        //                 let sp = tempHomeTeamSp[index];
        //                 sp = e.target.value;
        //                 tempHomeTeamSp[index] = sp;
        //                 setHomeTeamSp(tempHomeTeamSp);
        //               }}
        //             />
        //           </div>
        //         </div>
        //       ))}
        //       <div className="flex items-center">
        //         <div className="font-bold uppercase text-lightOnSurface dark:text-darkOnSurface w-1/2">
        //           Ev Sahibi Takım Kaptanı
        //         </div>
        //         <div className="w-1/2">
        //           <select
        //             name={`homePlayerCpt`}
        //             id={`homePlayerCpt`}
        //             className=" bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //             value={homeTeamCpt}
        //             onChange={(e) => {
        //               setError("");
        //               setHomeTeamCpt(e.target.value);
        //             }}

        //           >
        //             {homeTeamList.map((player) => (
        //               <option key={`option-${player.name}`} value={player.name}>
        //                 {player.name}
        //               </option>
        //             ))}
        //           </select>
        //         </div>
        //       </div>
        //     </div>
        //     {/* away team */}
        //     <div className="flex flex-col space-y-2 w-full my-4">
        //       <h1 className="text-lightOnSurface dark:text-darkOnSurface text-center text-2xl uppercase font-semibold underline underline-offset-4">
        //         Deplasman
        //       </h1>
        //       <div className="flex font-bold uppercase text-lightOnSurface dark:text-darkOnSurface">
        //         <div className="w-6/12 text-center">Oyuncu</div>
        //         <div className="w-3/12 text-center">SS</div>
        //         <div className="w-3/12 text-center">SP</div>
        //       </div>
        //       {eleven.map((index) => (
        //         <div
        //           key={`away-${index}`}
        //           className="flex justify-between space-x-2 sm:space-x-4 w-full"
        //         >
        //           <div className="w-6/12">
        //             <select
        //               name={`awayPlayer-${index}`}
        //               id={`awayPlayer-${index}`}
        //               className=" bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //               value={awayTeamRoster[index]}
        //               onChange={(e) => {
        //                 setError("");
        //                 let tempAwayTeamRoster = [...awayTeamRoster];
        //                 let player = tempAwayTeamRoster[index];
        //                 if (tempAwayTeamRoster.includes(e.target.value)) {
        //                   setError("Bu oyuncu zaten seçilmiş");
        //                 }
        //                 player = e.target.value;
        //                 tempAwayTeamRoster[index] = player;
        //                 setAwayTeamRoster(tempAwayTeamRoster);
        //               }}

        //             >
        //               {awayTeamList.map((player) => (
        //                 <option key={`option-${player.name}`} value={player.name}>
        //                   {player.name}
        //                 </option>
        //               ))}
        //             </select>
        //           </div>
        //           <div className="w-3/12">
        //             <input
        //               type="number"
        //               min={0}
        //               max={10}
        //               step={0.1}
        //               name={`awaySs-${index}`}
        //               id={`awaySs-${index}`}
        //               defaultValue={awayTeamSs[index]}
        //               className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //               onChange={(e) => {
        //                 let tempAwayTeamSs = [...awayTeamSs];
        //                 let ss = tempAwayTeamSs[index];
        //                 ss = e.target.value;
        //                 tempAwayTeamSs[index] = ss;
        //                 setAwayTeamSs(tempAwayTeamSs);
        //               }}
        //             />
        //           </div>
        //           <div className="w-3/12">
        //             <input
        //               type="number"
        //               min={0}
        //               max={15}
        //               step={1}
        //               name={`awaySp-${index}`}
        //               id={`awaySp-${index}`}
        //               defaultValue={awayTeamSp[index]}
        //               className="bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //               onChange={(e) => {
        //                 let tempAwayTeamSp = [...awayTeamSp];
        //                 let sp = tempAwayTeamSp[index];
        //                 sp = e.target.value;
        //                 tempAwayTeamSp[index] = sp;
        //                 setAwayTeamSp(tempAwayTeamSp);
        //               }}

        //             />
        //           </div>
        //         </div>
        //       ))}
        //       <div className="flex items-center">
        //         <div className="font-bold uppercase text-lightOnSurface dark:text-darkOnSurface w-1/2">
        //           Deplasman Takım Kaptanı
        //         </div>
        //         <div className="w-1/2">
        //           <select
        //             name={`awayPlayerCpt`}
        //             id={`awayPlayerCpt`}
        //             className=" bg-lightSurface border border-gray-300 text-lightOnSurface sm:text-sm rounded-lg block w-full p-2.5 dark:bg-darkSurface dark:border-darkPrimary dark:placeholder-gray-400 dark:text-darkOnSurface"
        //             value={awayTeamCpt}
        //             onChange={(e) => {
        //               setAwayTeamCpt(e.target.value);
        //             }}
        // disabled
        //    required
        //           >
        //             {awayTeamList.map((player) => (
        //               <option key={`optionCpt-${player.name}`} value={player.name}>
        //                 {player.name}
        //               </option>
        //             ))}
        //           </select>
        //         </div>
        //       </div>
        //     </div>
        //     <button
        //       disabled={loading}
        //       type="submit"
        //       className="w-full text-lightOnPrimary dark:text-darkOnPrimary bg-lightPrimary dark:bg-darkPrimary hover:bg-lightPrimaryVar dark:hover:bg-darkPrimaryVar dark:hover:text-darkOnSurface focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
        //     >
        //       Düzenle
        //     </button>
        //   </form>
        //   {loading && <Spinner />}
        // </div>
    );
};

export default EditResult;
