import React, { useState } from "react";
import { useAuth } from "../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, db } from "../firebase/setup";
import {
    doc,
    serverTimestamp,
    setDoc,
    where,
    query,
    collection,
    getDocs,
} from "firebase/firestore";
import { collectionName, errorCodes } from "../constObjects";

import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    Link,
    Paper,
    Divider,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { setLoading, setFeedback } from "../store/feedbackSlice";

const Register = () => {
    // STATES
    const [registerData, setRegisterData] = useState({
        email: "",
        password: "",
        team: "",
        manager: "",
        president: "",
        answer: "",
    });
    const [logoImg, setLogoImg] = useState();
    // HOOKS AND CONTEXTS
    const { register } = useAuth();
    const redirect = useNavigate();
    const dispatch = useDispatch();

    // Send register data
    const handleRegister = async (e) => {
        e.preventDefault();
        try {
            dispatch(setLoading(true));

            // check secret
            const secretsRef = collection(db, collectionName.secrets);
            const secretSnapshot = await getDocs(
                query(secretsRef, where("which", "==", "registration"))
            );
            if (
                secretSnapshot.docs[0].data().value !==
                registerData.answer.toLowerCase()
            ) {
                //eslint-disable-next-line
                throw { code: "user/incorrect-secret" };
            }

            // check existing team manager president
            const usersRef = collection(db, collectionName.users);
            const teamSnapshot = await getDocs(
                query(usersRef, where("team", "==", registerData.team))
            );
            if (!teamSnapshot.empty) {
                //eslint-disable-next-line
                throw { code: "user/team-aldready-in-use" };
            }
            const managerSnapshot = await getDocs(
                query(usersRef, where("manager", "==", registerData.manager))
            );
            if (!managerSnapshot.empty) {
                //eslint-disable-next-line
                throw { code: "user/manager-aldready-in-use" };
            }
            const presidentSnapshot = await getDocs(
                query(
                    usersRef,
                    where("president", "==", registerData.president)
                )
            );
            if (!presidentSnapshot.empty) {
                //eslint-disable-next-line
                throw { code: "user/president-aldready-in-use" };
            }

            // upload team logo
            const logoRef = ref(
                storage,
                `${registerData.team.toLowerCase()}.png`
            );
            await uploadBytes(logoRef, logoImg);

            // get uploaded team logo url
            const logoUrl = await getDownloadURL(
                ref(storage, `${registerData.team.toLowerCase()}.png`)
            );

            // register user
            const registerResponse = await register(
                registerData.email,
                registerData.password
            );
            // add user to db
            await setDoc(
                doc(db, collectionName.users, registerResponse.user.uid),
                {
                    email: registerData.email,
                    team: registerData.team,
                    admin: false,
                    manager: registerData.manager,
                    president: registerData.president,
                    logoUrl,
                    createdAt: serverTimestamp(),
                    updatedAt: serverTimestamp(),
                }
            );
            dispatch(
                setFeedback({ severity: "success", message: "Kayıt başarılı." })
            );
            redirect("/");
        } catch (e) {
            console.log(e.code);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            const errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(setFeedback({ severity: "error", message: errorMsg }));
        }
        dispatch(setLoading(false));
    };

    const handleChange = (e) => {
        e.preventDefault();
        setRegisterData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const handleImgChange = (e) => {
        setLogoImg(e.target.files[0]);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mb: 10,
                }}
            >
                <Typography component="h1" variant="h5">
                    Fantazi Liğ Şölenine Katıl!
                </Typography>
                <Box
                    component="form"
                    onSubmit={handleRegister}
                    sx={{
                        width: "100%", // Fix IE 11 issue.
                        mt: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="E-posta"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Şifre"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="team"
                        label="Takım İsmi"
                        id="team"
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="manager"
                        label="Teknik Direktör"
                        id="manager"
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="president"
                        label="Kulüp Başkanı"
                        id="president"
                        onChange={handleChange}
                    />

                    <Button
                        component="label"
                        role="undefined"
                        variant="contained"
                        sx={{ width: "100%" }}
                        startIcon={<CloudUpload />}
                    >
                        Kulüp Logosu
                        <VisuallyHiddenInput
                            type="file"
                            accept="image/png"
                            onChange={handleImgChange}
                        />
                    </Button>

                    <Paper elevation={3} sx={{ p: 2, mt: 2, width: "100%" }}>
                        <Typography variant="h6" gutterBottom>
                            Güvenlik Sorusu
                        </Typography>
                        <Divider sx={{ mb: 1 }} />
                        <Typography variant="body1" sx={{ mb: 1 }}>
                            Dünyanın gelmiş geçmiş en iyi futbolcusu kimdir?
                        </Typography>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="answer"
                            label="Cevap"
                            id="answer"
                            onChange={handleChange}
                        />
                    </Paper>

                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Kayıt ol
                    </Button>
                    <Link href="/giris" variant="body2">
                        {"Already have an account? Sign In"}
                    </Link>
                </Box>
            </Box>
        </Container>
    );
};

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

export default Register;
