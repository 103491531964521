import React from "react";
import { db } from "../firebase/setup";
import {
    doc,
    getDoc,
    getDocs,
    addDoc,
    serverTimestamp,
    where,
    query,
    collection,
    deleteDoc,
    onSnapshot,
} from "firebase/firestore";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../providers/AuthProvider";
import { collectionName, errorCodes } from "../constObjects";
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setLoading, setFeedback } from "../store/feedbackSlice";

// const draftPhases = [1, 2]; // add everytime a draft takes place
// const currentDraftPhase = draftPhases.slice(-1)[0];
const headerList = ["Oyuncu", "Mevki", "Takim", "Durum"];

const Draft = () => {
    const { currentUser } = useAuth();
    const { phase: displayPhase } = useParams();
    const redirect = useNavigate();
    const dispatch = useDispatch();

    const [currentPhase, setCurrentPhase] = useState(null);
    const [draftOpen, setDraftOpen] = useState(false);
    const [draftList, setDraftList] = useState([]);
    const [ownPlayerList, setOwnPlayerList] = useState([]);

    const [draftData, setDraftData] = useState({
        player: "",
        position: "gk",
        team: "",
        action: "draft",
        createdAt: null,
    });
    const [dropData, setDropData] = useState({
        player: "",
        position: "",
        team: "",
        action: "drop",
        createdAt: null,
    });

    useEffect(() => {
        dispatch(setLoading(true));

        const getControlData = async () => {
            const controlSnap = await getDoc(doc(db, "control", "control_doc"));
            if (controlSnap.exists()) {
                setCurrentPhase(controlSnap.data().draftPhase);
                setDraftOpen(controlSnap.data().draftOpen);
            }
        };

        // const getTeam = async () => {
        //   const userSnap = await getDoc(doc(db, collectionName.users, currentUser.uid));
        //   userSnap.exists() &&
        //     setDraftData((prev) => ({
        //       ...prev,
        //       team: userSnap.data().team,
        //     }));
        // };

        // const q = query(
        //   collection(db, collectionName.drafts),
        //   where("phase", "==", [displayPhase]),
        //   orderBy("createdAt", "desc")
        // );
        const unsubscribe = onSnapshot(
            collection(db, collectionName.drafts),
            (snapshot) => {
                const draftData = snapshot.docs.map((doc) => ({
                    draftId: doc.id,
                    ...doc.data(),
                }));
                const orderedDraftData = draftData.filter((data) => {
                    return (
                        data.phase === parseInt(displayPhase) ||
                        data.phase?.includes(parseInt(displayPhase))
                    );
                });
                orderedDraftData.sort(
                    (a, b) => b.createdAt.seconds - a.createdAt.seconds
                );
                setDraftList(orderedDraftData);
                if (
                    snapshot.docChanges().some((change) => change.type === " ")
                ) {
                    const lastDraft = snapshot.docs.at(0).data();

                    dispatch(
                        setFeedback({
                            severity: "info",
                            message: `${lastDraft.player}, ${lastDraft.team} tarafından draft edildi.`,
                        })
                    );
                }
            },
            (error) => {
                dispatch(
                    setFeedback({
                        severity: "error",
                        message: `Baferin ile iletişime geçiniz. ${error.name}: ${error.code}`,
                    })
                );
            }
        );

        // const getDraftList = async () => {
        //   const draftListRef = collection(db, collectionName.drafts);
        //   let phaseDraftList = [];

        //   const sortedDraftListSnapshot = await getDocs(
        //     query(draftListRef, where("phase", "==", [1]), orderBy("createdAt"))
        //   );

        //   if (!sortedDraftListSnapshot.empty) {
        //     sortedDraftListSnapshot.docs.forEach((doc) => {
        //       phaseDraftList.push({
        //         ...doc.data(),
        //         draftId: doc.id,
        //       });
        //     });
        //   } else return;
        //   console.log("phaseDraftList", phaseDraftList);
        //   setDraftList((prev) => [...prev, phaseDraftList]);
        // };

        const getOwnPlayerList = async () => {
            const ownPlayerListRef = collection(db, collectionName.players);
            const ownPlayerSnapshot = await getDocs(
                query(ownPlayerListRef, where("team", "==", currentUser.team))
            );
            if (!ownPlayerSnapshot.empty) {
                ownPlayerSnapshot.docs.forEach((doc) => {
                    setOwnPlayerList((prev) => [
                        ...prev,
                        {
                            player: doc.data().player,
                            position: doc.data().position,
                            playerId: doc.id,
                        },
                    ]);
                });
            }
        };

        getControlData();
        // getTeam();
        // getDraftList();
        if (currentPhase > 1) getOwnPlayerList();
        dispatch(setLoading(false));

        return () => unsubscribe();
        //eslint-disable-next-line
    }, []);

    const handleDraft = async (e) => {
        e.preventDefault();
        try {
            // dispatch(setLoading(true));
            // check if player already drafted
            const playersRef = collection(db, collectionName.players);
            const playerSnapshot = await getDocs(
                query(
                    playersRef,
                    where("player", "==", `${draftData.player.toUpperCase()}`)
                )
            );

            if (!playerSnapshot.empty) {
                // eslint-disable-next-line
                throw {
                    code: "draft/player-already-drafted",
                    player: draftData.player.toUpperCase(),
                };
            }
            // add draft
            await addDoc(collection(db, collectionName.drafts), {
                player: draftData.player.toUpperCase(),
                position: draftData.position,
                team: currentUser.team,
                phase: currentPhase,
                action: draftData.action,
                createdAt: serverTimestamp(),
            });
            // add player to his team
            await addDoc(collection(db, collectionName.players), {
                player: draftData.player.toUpperCase(),
                position: draftData.position,
                team: currentUser.team,
                createdAt: serverTimestamp(),
            });
        } catch (e) {
            console.log(e.code);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            if (e.player) errorMsg += ` [${draftData.player.toUpperCase()}]`;
            dispatch(setFeedback({ severity: "error", message: errorMsg }));
        }
        dispatch(setLoading(false));
    };

    const handleDrop = async (e) => {
        e.preventDefault();
        try {
            dispatch(setLoading(true));

            // dont let drop in 1st draft
            if (currentPhase === 1) return;

            // get info for player to be dropped
            const droppedPlayer = ownPlayerList.find(
                (i) => i.player === dropData.player
            );
            // add dropped player
            await addDoc(collection(db, collectionName.drafts), {
                player: dropData.player.toUpperCase(),
                position: droppedPlayer.position,
                team: currentUser.team,
                phase: currentPhase,
                action: dropData.action,
                createdAt: serverTimestamp(),
            });
            // remove from players
            await deleteDoc(
                doc(db, collectionName.players, droppedPlayer.playerId)
            );

            redirect(0);
        } catch (e) {
            console.log(e.code);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            const errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(setFeedback({ severity: "error", message: errorMsg }));
        }
        dispatch(setLoading(false));
    };

    const handleChange = (e) => {
        e.preventDefault();
        setDraftData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <React.Fragment>
            <Box
                component="main"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 2,
                    width: "100%",
                }}
            >
                {currentPhase > 1 &&
                    currentPhase === parseInt(displayPhase) && (
                        <Stack
                            component="form"
                            onSubmit={handleDrop}
                            spacing={2}
                            width="100%"
                        >
                            <FormControl fullWidth>
                                <InputLabel id="drop-player">
                                    Oyuncu bırak
                                </InputLabel>
                                <Select
                                    labelId="drop-player"
                                    onChange={(e) => {
                                        setDropData((prev) => ({
                                            ...prev,
                                            playerId: e.target.value,
                                        }));
                                    }}
                                    label="Mevki"
                                    defaultValue=""
                                    required
                                >
                                    {ownPlayerList.map((item) => (
                                        <MenuItem
                                            key={item.playerId}
                                            value={item.playerId}
                                        >
                                            {item.player}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                sx={{ alignSelf: "center" }}
                                disabled={!draftOpen}
                            >
                                Bırak
                            </Button>
                        </Stack>
                    )}
                {currentPhase === parseInt(displayPhase) && (
                    <Stack
                        component="form"
                        onSubmit={handleDraft}
                        spacing={2}
                        width="100%"
                    >
                        <TextField
                            label="Oyuncu ismi"
                            name="player"
                            onChange={handleChange}
                            fullWidth
                            required
                        />
                        <FormControl fullWidth>
                            <InputLabel id="draft-position">Mevki</InputLabel>
                            <Select
                                name="position"
                                labelId="draft-position"
                                onChange={handleChange}
                                label="Mevki"
                                defaultValue="gk"
                                required
                            >
                                <MenuItem value="gk">Kaleci</MenuItem>
                                <MenuItem value="df">Savunma</MenuItem>
                                <MenuItem value="mf">Orta Saha</MenuItem>
                                <MenuItem value="fw">Hücum</MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            sx={{ alignSelf: "center" }}
                            disabled={!draftOpen}
                            onClick={handleDraft}
                        >
                            Draftla
                        </Button>
                    </Stack>
                )}
                <TableContainer component={Paper} sx={{ mb: 8 }}>
                    <Typography variant="h6" align="center" gutterBottom>
                        {displayPhase}. Draft
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {headerList.map((header, index) => (
                                    <TableCell
                                        key={`table-header-cell-${index}`}
                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {draftList?.map((draft, index) => (
                                <TableRow
                                    key={`table-body-row-${draft.draftId}`}
                                >
                                    <TableCell>{draft.player}</TableCell>
                                    <TableCell>{draft.position}</TableCell>
                                    <TableCell>{draft.team}</TableCell>
                                    <TableCell>{draft.action}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment>
    );
};

export default Draft;
