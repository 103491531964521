import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";

const headerList = ["Oyuncu", "SofaScore", "SkillPoint"];
const colWidthList = ["60%", "20%", "20%"];
// const keyList = ["player", "position", "team", "action"];

const ResultTabPanel = (props) => {
    const { roster, cpt, ss, sp, totalSs, totalSp, value, index, ...other } =
        props;

    const getBackgroundColor = (value) => {
        if (value >= 9) return "blue";
        if (value >= 8) return "darkgreen";
        if (value >= 7) return "lightgreen";
        if (value >= 6.5) return "yellow";
        if (value >= 6) return "orange";
        return "red";
    };

    const getTextColor = (value) => {
        if (value >= 9) return "white";
        if (value >= 8) return "white";
        if (value >= 7) return "black";
        if (value >= 6.5) return "black";
        if (value >= 6) return "black";
        return "white";
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`result-tabpanel-${index}`}
            aria-labelledby={`result-tab-${index}`}
            style={{ width: "100%" }}
            {...other}
        >
            {value === index && (
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {headerList.map((header, index) => (
                                    <TableCell
                                        key={`table-header-cell-${index}`}
                                        sx={{ width: colWidthList[index] }}
                                    >
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {roster.map((player, index) => (
                                <TableRow key={`table-body-row-${index}`}>
                                    <TableCell sx={{ textAlign: "left" }}>
                                        {player === cpt
                                            ? `${player} (C)`
                                            : player}
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                        <Box
                                            component="span"
                                            sx={{
                                                color: getTextColor(ss[index]),
                                                backgroundColor:
                                                    getBackgroundColor(
                                                        ss[index]
                                                    ),
                                                padding: 1,
                                                borderRadius: 1,
                                                width: 36,
                                                display: "block",
                                            }}
                                        >
                                            {ss[index] === "10"
                                                ? parseFloat(ss[index]).toFixed(
                                                      0
                                                  )
                                                : parseFloat(ss[index]).toFixed(
                                                      1
                                                  )}
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={{ textAlign: "center" }}>
                                        {sp[index]}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>Toplam</TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                    {totalSs.toFixed(1)}
                                </TableCell>
                                <TableCell sx={{ textAlign: "center" }}>
                                    {totalSp}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            )}
        </div>
    );
};

ResultTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    // roster, cpt, ss, sp, totalSs, totalSp,
    roster: PropTypes.arrayOf(PropTypes.string).isRequired,
    cpt: PropTypes.string.isRequired,
    ss: PropTypes.arrayOf(PropTypes.string).isRequired,
    sp: PropTypes.arrayOf(PropTypes.string).isRequired,
    totalSs: PropTypes.number.isRequired,
    totalSp: PropTypes.number.isRequired,
};

export default ResultTabPanel;
