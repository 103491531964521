import { useState } from "react";
import {
    Button,
    MenuItem,
    TextField,
    Typography,
    FormControl,
    InputLabel,
    Select,
    Stack,
    Paper,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setFeedback } from "../store/feedbackSlice";

const DisplayResultSelector = ({
    matchResults,
    setPopupOpen,
    resultToDisplay,
    setResultToDisplay,
}) => {
    const [searchTerm, setSearchTerm] = useState("");

    const dispatch = useDispatch();

    const handleSearchChange = (event) => {
        setResultToDisplay("");
        setSearchTerm(event.target.value);
    };

    const filteredMatches = matchResults.filter(
        (match) =>
            match.week.includes(searchTerm) ||
            match.homeTeam.toLowerCase().includes(searchTerm.toLowerCase()) ||
            match.awayTeam.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Paper
            elevation={1}
            sx={{
                p: 2,
                mt: 2,
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Typography variant="h6">Maç Sonucu Görüntüle</Typography>
            <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="baseline"
                spacing={2}
                sx={{ mt: 2, width: "100%" }}
            >
                <TextField
                    fullWidth
                    label="Hafta veya Takım ara"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    variant="outlined"
                />
                <FormControl sx={{ mt: 2, width: "100%" }}>
                    <InputLabel id="select-match-label">Maç seç</InputLabel>
                    <Select
                        labelId="select-match-label"
                        value={resultToDisplay}
                        onChange={(event) =>
                            setResultToDisplay(event.target.value)
                        }
                        label="Select Match"
                    >
                        {filteredMatches.map((match, index) => (
                            <MenuItem
                                key={`filteredMatch-${index}`}
                                value={`w${match.week}m${match.match}`}
                            >
                                Hafta {match.week}: {match.homeTeam} vs{" "}
                                {match.awayTeam}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Stack>
            <Button
                fullWidth
                variant="contained"
                sx={{ mt: 3 }}
                onClick={() => {
                    if (!resultToDisplay)
                        dispatch(
                            setFeedback({
                                severity: "error",
                                message: "Görüntülemek için maç seç!",
                            })
                        );
                    setPopupOpen(true);
                }}
            >
                Sonucu göster
            </Button>
        </Paper>
    );
};

export default DisplayResultSelector;
