import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";

// import { doc, getDoc } from "firebase/firestore";
// import { db } from "../firebase/setup";

import { useColorMode } from "../providers/ColorModeProvider";
import { useAuth } from "../providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import flLogoLight from "../images/logo.png";
import flLogoDark from "../images/logo_dark.png";

const pages = [
    { name: "Lig", href: "/lig" },
    { name: "Takımlar", href: "/takimlar" },
    { name: "Draft", href: "/draft" },
    { name: "KAP", href: "/kap" },
];

const draftPhase = 1;

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    // const [draftPhase, setDraftPhase] = React.useState("");

    const { toggleColorMode, colorMode } = useColorMode();
    const { currentUser, logOut } = useAuth();
    const redirect = useNavigate();

    // React.useEffect(() => {
    //   const getCurrentPhase = async () => {
    //     const controlSnap = await getDoc(doc(db, "control", "control_doc"));
    //     if (controlSnap.exists()) {
    //       setDraftPhase(controlSnap.data().draftPhase);
    //     }
    //   };

    //   return () => {
    //     getCurrentPhase();
    //   };
    // }, []);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleToggleColorMode = () => {
        toggleColorMode();
        handleCloseUserMenu();
    };

    const handleLogOut = async () => {
        try {
            await logOut();
            handleCloseUserMenu();
        } catch (e) {
            console.log(e.code);
        }
        redirect("/");
    };

    return (
        <AppBar position="static" sx={{ mb: 4 }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link
                        href="/"
                        sx={{
                            mr: 2,
                            display: { xs: "none", md: "flex" },
                            fontWeight: 700,
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            component="img"
                            src={
                                colorMode === "dark" ? flLogoDark : flLogoLight
                            }
                            alt={"fl-logo-" + colorMode}
                            sx={{
                                height: "2rem",
                                display: { xs: "none", md: "flex" },
                                mr: 1,
                            }}
                        />
                        <Typography variant="h6" noWrap fontWeight="bold">
                            2025
                        </Typography>
                    </Link>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    component={Link}
                                    key={page.name + "-link"}
                                    href={
                                        page.href === "/draft"
                                            ? page.href + "/" + draftPhase
                                            : page.href
                                    }
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        {page.name}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>

                    <Link
                        href="/"
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                            textDecoration: "none",
                        }}
                    >
                        <Box
                            component="img"
                            src={
                                colorMode === "dark" ? flLogoDark : flLogoLight
                            }
                            alt={"fl-logo-" + colorMode}
                            sx={{
                                height: "2rem",
                                display: { xs: "flex", md: "none" },
                                mr: 1,
                            }}
                        />

                        <Typography
                            variant="h5"
                            noWrap
                            sx={{
                                mr: 2,
                                fontWeight: 700,
                                textDecoration: "none",
                            }}
                        >
                            2025
                        </Typography>
                    </Link>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.name + "-page"}
                                href={
                                    page.href === "/draft"
                                        ? page.href + "/" + draftPhase
                                        : page.href
                                }
                                onClick={handleCloseNavMenu}
                                color="inherit"
                                sx={{
                                    my: 2,
                                    display: "block",
                                    textAlign: "center",
                                }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    <Box
                        sx={{
                            flexGrow: 0,
                            display: currentUser ? "block" : "none",
                        }}
                    >
                        <Tooltip title="Ayarlar">
                            <IconButton
                                onClick={handleOpenUserMenu}
                                sx={{ p: 0 }}
                            >
                                <Avatar
                                    alt={currentUser?.team}
                                    src={currentUser?.logoUrl}
                                />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: "45px" }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {currentUser?.admin && (
                                <MenuItem
                                    component={Link}
                                    key="admin-paneli"
                                    onClick={handleCloseUserMenu}
                                    href={`/admin`}
                                >
                                    <Typography textAlign="center">
                                        Admin Paneli
                                    </Typography>
                                </MenuItem>
                            )}
                            {currentUser?.admin && (
                                <MenuItem
                                    component={Link}
                                    key="yeni-sonuc"
                                    onClick={handleCloseUserMenu}
                                    href={`/yeni-sonuc`}
                                    divider
                                >
                                    <Typography textAlign="center">
                                        Yeni Maç Sonucu
                                    </Typography>
                                </MenuItem>
                            )}
                            <MenuItem
                                component={Link}
                                key="takimim"
                                onClick={handleCloseUserMenu}
                                href={`/profil/${currentUser?.uid}`}
                            >
                                <Typography textAlign="center">
                                    Takımım
                                </Typography>
                            </MenuItem>
                            <MenuItem
                                key="tema-degistir"
                                onClick={handleToggleColorMode}
                                divider
                            >
                                <Typography textAlign="center">
                                    Tema değiştir
                                </Typography>
                            </MenuItem>
                            <MenuItem key="cikis-yap" onClick={handleLogOut}>
                                <Typography textAlign="center">
                                    Çıkış yap
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
