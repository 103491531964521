import React from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from "@mui/material";

const RosterList = ({ gkList, dfList, mfList, fwList }) => {
    const players = [
        ...gkList.map((player) => ({
            player: player.player,
            position: "Kaleci",
        })),
        ...dfList.map((player) => ({
            player: player.player,
            position: "Savunma",
        })),
        ...mfList.map((player) => ({
            player: player.player,
            position: "Orta Saha",
        })),
        ...fwList.map((player) => ({
            player: player.player,
            position: "Forvet",
        })),
    ];
    return (
        <TableContainer component={Paper} sx={{ mb: 8 }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <strong>Oyuncu</strong>
                        </TableCell>
                        <TableCell>
                            <strong>Pozisyonu</strong>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.map((player, index) => (
                        <TableRow key={index}>
                            <TableCell>{player.player}</TableCell>
                            <TableCell>{player.position}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default RosterList;
