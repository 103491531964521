import React from "react";
import { Outlet } from "react-router-dom";

import NavbarMUI from "./layouts/NavbarMUI";

import { Box, CssBaseline, Container } from "@mui/material";
import Spinner from "./components/Spinner";
import Feedback from "./components/Feedback";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
    return (
        <React.Fragment>
            <CssBaseline enableColorScheme />
            <Box
                minHeight="100vh"
                display="flex"
                flexDirection="column"
                justifyContent="start"
            >
                <NavbarMUI />
                <Container
                    maxWidth="md"
                    sx={{
                        display: "flex",
                        flexGrow: 1,
                        justifyContent: "center",
                        alignItems: "start",
                    }}
                >
                    <Outlet />
                    <Spinner />
                    <Feedback />
                    <ScrollToTop />
                </Container>
            </Box>
        </React.Fragment>
    );
};

export default App;
