import {
    collection,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    doc,
    serverTimestamp,
    updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase/setup";
import { collectionName, errorCodes, severity } from "../constObjects";
import {
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Typography,
    Box,
    Grid,
    Paper,
    Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setFeedback, setLoading } from "../store/feedbackSlice";
import { useAuth } from "../providers/AuthProvider";

const eleven = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const NewResult = () => {
    const [teamList, setTeamList] = useState([]);
    const [week, setWeek] = useState("");
    const [match, setMatch] = useState("");
    const [homeTeam, setHomeTeam] = useState("");
    const [awayTeam, setAwayTeam] = useState("");
    const [homeTeamList, setHomeTeamList] = useState([]);
    const [awayTeamList, setAwayTeamList] = useState([]);
    const [homeTeamRoster, setHomeTeamRoster] = useState([]);
    const [homeTeamCpt, setHomeTeamCpt] = useState("");
    const [awayTeamCpt, setAwayTeamCpt] = useState("");
    const [homeTeamSs, setHomeTeamSs] = useState([
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
    ]);
    const [homeTeamSp, setHomeTeamSp] = useState([
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
    ]);
    const [awayTeamRoster, setAwayTeamRoster] = useState([]);
    const [awayTeamSs, setAwayTeamSs] = useState([
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
        "0.0",
    ]);
    const [awayTeamSp, setAwayTeamSp] = useState([
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
        "0",
    ]);
    const redirect = useNavigate();
    const dispatch = useDispatch();
    const { currentUser } = useAuth();
    // get all teams list
    useEffect(() => {
        const getTeamList = async () => {
            dispatch(setLoading(true));
            try {
                const usersRef = collection(db, collectionName.users);
                const usersSnapshot = await getDocs(usersRef);
                if (!usersSnapshot.empty) {
                    usersSnapshot.docs.forEach((doc) => {
                        setTeamList((prev) => [...prev, doc.data().team]);
                    });
                }
            } catch (e) {
                console.log(e.code);
                const errorObj = errorCodes.filter(
                    (error) => error.code === e.code
                );
                let errorMsg = Object.keys(errorObj).length
                    ? errorObj[0].message || ""
                    : "Tanımlanmamış hata.";
                dispatch(
                    setFeedback({ severity: severity.error, message: errorMsg })
                );
            }
            dispatch(setLoading(false));
        };
        const getMatchWeek = async () => {
            const controlSnap = await getDoc(
                doc(db, collectionName.control, "control_doc")
            );
            if (controlSnap.exists()) {
                setWeek(controlSnap.data().weekNumber.toString());
            }
        };
        getTeamList();
        getMatchWeek();
    }, [dispatch]);

    // get home team list
    useEffect(() => {
        setHomeTeamList([]);
        const getHomeTeamPlayers = async () => {
            dispatch(setLoading(true));
            try {
                let tempHomeTeamList = [];
                const homeTeamPlayersRef = collection(
                    db,
                    collectionName.players
                );
                const homeTeamPlayersSnapshot = await getDocs(
                    query(homeTeamPlayersRef, where("team", "==", homeTeam))
                );
                if (!homeTeamPlayersSnapshot.empty) {
                    homeTeamPlayersSnapshot.docs.forEach((doc) => {
                        tempHomeTeamList.push({
                            name: doc.data().player,
                            id: doc.id,
                        });
                    });
                }
                tempHomeTeamList.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                setHomeTeamList(tempHomeTeamList);
            } catch (e) {
                console.log(e.code);
                const errorObj = errorCodes.filter(
                    (error) => error.code === e.code
                );
                let errorMsg = Object.keys(errorObj).length
                    ? errorObj[0].message || ""
                    : "Tanımlanmamış hata.";
                dispatch(
                    setFeedback({ severity: severity.error, message: errorMsg })
                );
            }
            dispatch(setLoading(false));
        };
        getHomeTeamPlayers();
    }, [homeTeam, dispatch]);

    // get away team list
    useEffect(() => {
        setAwayTeamList([]);
        const getAwayTeamPlayers = async () => {
            dispatch(setLoading(true));

            try {
                let tempAwayTeamList = [];
                const awayTeamPlayersRef = collection(
                    db,
                    collectionName.players
                );
                const awayTeamPlayersSnapshot = await getDocs(
                    query(awayTeamPlayersRef, where("team", "==", awayTeam))
                );
                if (!awayTeamPlayersSnapshot.empty) {
                    awayTeamPlayersSnapshot.docs.forEach((doc) => {
                        tempAwayTeamList.push({
                            name: doc.data().player,
                            id: doc.id,
                        });
                    });
                }
                tempAwayTeamList.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                setAwayTeamList(tempAwayTeamList);
            } catch (e) {
                console.log(e.code);
                const errorObj = errorCodes.filter(
                    (error) => error.code === e.code
                );
                let errorMsg = Object.keys(errorObj).length
                    ? errorObj[0].message || ""
                    : "Tanımlanmamış hata.";
                dispatch(
                    setFeedback({ severity: severity.error, message: errorMsg })
                );
            }
            dispatch(setLoading(false));
        };
        getAwayTeamPlayers();
    }, [awayTeam, dispatch]);

    const containsDuplicates = (roster) => {
        if (roster.length !== new Set(roster).size) return true;
        return false;
    };

    const handleNewResult = async (e) => {
        e.preventDefault();
        try {
            dispatch(setLoading(true));
            // eslint-disable-next-line
            if (!currentUser.admin) throw { code: "admin/not-admin" };
            // find duplicate players
            if (
                containsDuplicates(homeTeamRoster) &&
                containsDuplicates(awayTeamRoster)
            ) {
                //eslint-disable-next-line
                throw { code: "results/duplicate-player" };
            }

            // find cpt indices
            const homeCptInd = homeTeamRoster.indexOf(homeTeamCpt);
            const awayCptInd = awayTeamRoster.indexOf(awayTeamCpt);

            // sum up sofascores and skill points
            const homeTeamTotalSs = homeTeamSs.reduce((prev, cur, ind) => {
                if (ind === homeCptInd) cur = cur * 2;
                return prev + parseFloat(cur);
            }, 0);
            const homeTeamTotalSp = homeTeamSp.reduce(
                (prev, cur) => prev + parseInt(cur),
                0
            );
            const awayTeamTotalSs = awayTeamSs.reduce((prev, cur, ind) => {
                if (ind === awayCptInd) cur = cur * 2;
                return prev + parseFloat(cur);
            }, 0);
            const awayTeamTotalSp = awayTeamSp.reduce(
                (prev, cur) => prev + parseInt(cur),
                0
            );

            // get current home team standing
            let tempHomeForms;
            let tempHomeWins;
            let tempHomeDraws;
            let tempHomeLosses;
            let tempHomePoints;
            let tempHomeSss;
            let tempHomeSps;

            const homeTeamStandingRef = doc(
                db,
                collectionName.standings,
                homeTeam
            );
            const homeTeamStandingSnapshot = await getDoc(homeTeamStandingRef);
            if (homeTeamStandingSnapshot.exists) {
                const currentHomeStanding = homeTeamStandingSnapshot.data();
                tempHomeForms = currentHomeStanding?.forms || []; // array
                tempHomeWins = currentHomeStanding?.wins || []; // array
                tempHomeDraws = currentHomeStanding?.draws || []; // array
                tempHomeLosses = currentHomeStanding?.losses || []; // array
                tempHomePoints = currentHomeStanding?.points || []; // array
                tempHomeSss = currentHomeStanding?.sss || []; // array
                tempHomeSps = currentHomeStanding?.sps || []; // array
            }
            // get current away team standing
            let tempAwayForms;
            let tempAwayWins;
            let tempAwayDraws;
            let tempAwayLosses;
            let tempAwayPoints;
            let tempAwaySss;
            let tempAwaySps;

            const awayTeamStandingRef = doc(
                db,
                collectionName.standings,
                awayTeam
            );
            const awayTeamStandingSnapshot = await getDoc(awayTeamStandingRef);
            if (awayTeamStandingSnapshot.exists) {
                const currentAwayStanding = awayTeamStandingSnapshot.data();
                tempAwayForms = currentAwayStanding?.forms || []; // array
                tempAwayWins = currentAwayStanding?.wins || []; // array
                tempAwayDraws = currentAwayStanding?.draws || []; // array
                tempAwayLosses = currentAwayStanding?.losses || []; // array
                tempAwayPoints = currentAwayStanding?.points || []; // array
                tempAwaySss = currentAwayStanding?.sss || []; // array
                tempAwaySps = currentAwayStanding?.sps || []; // array
            }

            // determine winner and loser
            if (homeTeamTotalSs > awayTeamTotalSs) {
                // home wins
                tempHomeForms.push("w");
                tempHomeWins.push(1);
                tempHomeDraws.push(0);
                tempHomeLosses.push(0);
                tempHomePoints.push(3);
                tempHomeSss.push(parseFloat(homeTeamTotalSs.toFixed(1)));
                tempHomeSps.push(homeTeamTotalSp);
                // away loses
                tempAwayForms.push("l");
                tempAwayWins.push(0);
                tempAwayDraws.push(0);
                tempAwayLosses.push(1);
                tempAwayPoints.push(0);
                tempAwaySss.push(parseFloat(awayTeamTotalSs.toFixed(1)));
                tempAwaySps.push(awayTeamTotalSp);
            } else if (awayTeamTotalSs > homeTeamTotalSs) {
                // away wins
                tempAwayForms.push("w");
                tempAwayWins.push(1);
                tempAwayDraws.push(0);
                tempAwayLosses.push(0);
                tempAwayPoints.push(3);
                tempAwaySss.push(parseFloat(awayTeamTotalSs.toFixed(1)));
                tempAwaySps.push(awayTeamTotalSp);
                // home loses
                tempHomeForms.push("l");
                tempHomeWins.push(0);
                tempHomeDraws.push(0);
                tempHomeLosses.push(1);
                tempHomePoints.push(0);
                tempHomeSss.push(parseFloat(homeTeamTotalSs.toFixed(1)));
                tempHomeSps.push(homeTeamTotalSp);
            } else {
                // away draws
                tempAwayForms.push("d");
                tempAwayWins.push(0);
                tempAwayDraws.push(1);
                tempAwayLosses.push(0);
                tempAwayPoints.push(1);
                tempAwaySss.push(parseFloat(awayTeamTotalSs.toFixed(1)));
                tempAwaySps.push(awayTeamTotalSp);
                // home draws
                tempHomeForms.push("d");
                tempHomeWins.push(0);
                tempHomeDraws.push(1);
                tempHomeLosses.push(0);
                tempHomePoints.push(1);
                tempHomeSss.push(parseFloat(homeTeamTotalSs.toFixed(1)));
                tempHomeSps.push(homeTeamTotalSp);
            }

            // update home team standing
            await updateDoc(homeTeamStandingRef, {
                points: tempHomePoints,
                forms: tempHomeForms,
                wins: tempHomeWins,
                draws: tempHomeDraws,
                losses: tempHomeLosses,
                sss: tempHomeSss,
                sps: tempHomeSps,
                lastUpdated: serverTimestamp(),
            });
            // update away team standing
            await updateDoc(awayTeamStandingRef, {
                points: tempAwayPoints,
                forms: tempAwayForms,
                wins: tempAwayWins,
                draws: tempAwayDraws,
                losses: tempAwayLosses,
                sss: tempAwaySss,
                sps: tempAwaySps,
                lastUpdated: serverTimestamp(),
            });

            // result data
            const resultData = {
                week,
                match,
                homeTeam,
                homeTeamRoster,
                homeTeamCpt,
                homeTeamSs,
                homeTeamTotalSs,
                homeTeamSp,
                homeTeamTotalSp,
                awayTeam,
                awayTeamRoster,
                awayTeamCpt,
                awayTeamSs,
                awayTeamTotalSs,
                awayTeamSp,
                awayTeamTotalSp,
                createdAt: serverTimestamp(),
            };

            // add document
            await setDoc(
                doc(db, collectionName.results, `w${week}m${match}`),
                resultData
            );
            dispatch(
                setFeedback({
                    severity: severity.success,
                    message: "Maç sonucu eklendi.",
                })
            );
            // refresh page
            redirect(0);
        } catch (e) {
            console.log(e);
            const errorObj = errorCodes.filter(
                (error) => error.code === e.code
            );
            let errorMsg = Object.keys(errorObj).length
                ? errorObj[0].message || ""
                : "Tanımlanmamış hata.";
            dispatch(
                setFeedback({ severity: severity.error, message: errorMsg })
            );
        }
        dispatch(setLoading(false));
    };

    return (
        <Box
            component="form"
            onSubmit={handleNewResult}
            sx={{ width: "100%", textAlign: "center", mb: 8 }}
        >
            <Typography variant="h4" sx={{ textAlign: "center", mb: 4 }}>
                Yeni Maç Sonucu Ekle
            </Typography>

            <Paper elevation={1} sx={{ flexGrow: 1, p: 2, mb: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel>Ev Sahibi</InputLabel>
                            <Select
                                name="homeTeam"
                                id="homeTeam"
                                onChange={(e) => setHomeTeam(e.target.value)}
                                required
                            >
                                {teamList.map((team) => (
                                    <MenuItem
                                        key={`option-${team}`}
                                        value={team}
                                    >
                                        {team}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl fullWidth>
                            <InputLabel>Deplasman</InputLabel>
                            <Select
                                name="awayTeam"
                                id="awayTeam"
                                onChange={(e) => setAwayTeam(e.target.value)}
                                required
                            >
                                {teamList.map((team) => (
                                    <MenuItem
                                        key={`option-${team}`}
                                        value={team}
                                    >
                                        {team}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Hafta"
                            name="week"
                            id="week"
                            value={week}
                            onChange={(e) => setWeek(e.target.value)}
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            label="Maç"
                            name="match"
                            id="match"
                            onChange={(e) => setMatch(e.target.value)}
                            required
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Paper>

            {/* home team */}
            <Paper elevation={1} sx={{ my: 4, p: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Ev Sahibi
                </Typography>

                {eleven.map((index) => (
                    <Grid
                        container
                        spacing={2}
                        key={`home-${index}`}
                        sx={{ mt: 1 }}
                    >
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    Ev Sahibi Oyuncu {index + 1}
                                </InputLabel>
                                <Select
                                    name={`homePlayer-${index}`}
                                    id={`homePlayer-${index}`}
                                    onChange={(e) => {
                                        let tempHomeTeamRoster = [
                                            ...homeTeamRoster,
                                        ];
                                        if (
                                            tempHomeTeamRoster.includes(
                                                e.target.value
                                            )
                                        ) {
                                            dispatch(
                                                setFeedback({
                                                    severity: severity.error,
                                                    message:
                                                        "Bu oyuncu zaten seçilmiş.",
                                                })
                                            );
                                            return;
                                        }
                                        tempHomeTeamRoster[index] =
                                            e.target.value;
                                        setHomeTeamRoster(tempHomeTeamRoster);
                                    }}
                                    required
                                >
                                    {homeTeamList.map((player) => (
                                        <MenuItem
                                            key={`option-${player.name}`}
                                            value={player.name}
                                        >
                                            {player.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SS"
                                value={homeTeamSs[index]}
                                inputProps={{ min: 0, max: 10, step: 0.1 }}
                                name={`homeSs-${index}`}
                                id={`homeSs-${index}`}
                                onChange={(e) => {
                                    let tempHomeTeamSs = [...homeTeamSs];
                                    tempHomeTeamSs[index] = e.target.value;
                                    setHomeTeamSs(tempHomeTeamSs);
                                }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SP"
                                value={homeTeamSp[index]}
                                inputProps={{ min: 0, max: 15, step: 1 }}
                                name={`homeSp-${index}`}
                                id={`homeSp-${index}`}
                                onChange={(e) => {
                                    let tempHomeTeamSp = [...homeTeamSp];
                                    tempHomeTeamSp[index] = e.target.value;
                                    setHomeTeamSp(tempHomeTeamSp);
                                }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={0}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                    </Grid>
                ))}
                <FormControl fullWidth>
                    <InputLabel>Ev Sahibi Takım Kaptanı</InputLabel>
                    <Select
                        name="homePlayerCpt"
                        id="homePlayerCpt"
                        onChange={(e) => setHomeTeamCpt(e.target.value)}
                        required
                    >
                        {homeTeamList.map((player) => (
                            <MenuItem
                                key={`option-${player.name}`}
                                value={player.name}
                            >
                                {player.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>

            {/* away team */}
            <Paper elevation={1} sx={{ my: 4, p: 2 }}>
                <Typography variant="h5" align="center" gutterBottom>
                    Deplasman
                </Typography>

                {eleven.map((index) => (
                    <Grid
                        container
                        spacing={2}
                        key={`away-${index}`}
                        sx={{ mt: 1 }}
                    >
                        <Grid item xs={12} sm={8}>
                            <FormControl fullWidth>
                                <InputLabel>
                                    Deplasman Oyuncu {index + 1}
                                </InputLabel>
                                <Select
                                    name={`awayPlayer-${index}`}
                                    id={`awayPlayer-${index}`}
                                    onChange={(e) => {
                                        let tempAwayTeamRoster = [
                                            ...awayTeamRoster,
                                        ];
                                        if (
                                            tempAwayTeamRoster.includes(
                                                e.target.value
                                            )
                                        ) {
                                            dispatch(
                                                setFeedback({
                                                    severity: severity.error,
                                                    message:
                                                        "Bu oyuncu zaten seçilmiş.",
                                                })
                                            );
                                            return;
                                        }
                                        tempAwayTeamRoster[index] =
                                            e.target.value;
                                        setAwayTeamRoster(tempAwayTeamRoster);
                                    }}
                                    required
                                >
                                    {awayTeamList.map((player) => (
                                        <MenuItem
                                            key={`option-${player.name}`}
                                            value={player.name}
                                        >
                                            {player.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SS"
                                value={awayTeamSs[index]}
                                inputProps={{ min: 0, max: 10, step: 0.1 }}
                                name={`awaySs-${index}`}
                                id={`awaySs-${index}`}
                                onChange={(e) => {
                                    let tempAwayTeamSs = [...awayTeamSs];
                                    tempAwayTeamSs[index] = e.target.value;
                                    setAwayTeamSs(tempAwayTeamSs);
                                }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                type="number"
                                label="SP"
                                value={awayTeamSp[index]}
                                inputProps={{ min: 0, max: 15, step: 1 }}
                                name={`awaySp-${index}`}
                                id={`awaySp-${index}`}
                                onChange={(e) => {
                                    let tempAwayTeamSp = [...awayTeamSp];
                                    tempAwayTeamSp[index] = e.target.value;
                                    setAwayTeamSp(tempAwayTeamSp);
                                }}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={0}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                    </Grid>
                ))}

                <FormControl fullWidth>
                    <InputLabel>Deplasman Takımı Kaptanı</InputLabel>
                    <Select
                        name="awayPlayerCpt"
                        id="awayPlayerCpt"
                        onChange={(e) => setAwayTeamCpt(e.target.value)}
                        required
                    >
                        {awayTeamList.map((player) => (
                            <MenuItem
                                key={`option-${player.name}`}
                                value={player.name}
                            >
                                {player.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>
            <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ alignSelf: "center" }}
            >
                Ekle
            </Button>
        </Box>
    );
};

export default NewResult;
