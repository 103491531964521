import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./providers/AuthProvider";
import { ColorModeProvider } from "./providers/ColorModeProvider";
import { Provider } from "react-redux";
import store from "./store/store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Register from "./pages/Register";
import SignIn from "./pages/SignIn";
import ExportImport from "./pages/ExportImport";
import PrivateRoutes from "./components/PrivateRoutes";
import Profile from "./pages/Profile";
import League from "./pages/League";
import Admin from "./pages/Admin";
import Kap from "./pages/Kap";
import Draft from "./pages/Draft";
import Teams from "./pages/Teams";
import EditResult from "./pages/EditResult";
import NewResult from "./pages/NewResult";
import ImportFixture from "./pages/ImportFixture";

const router = createBrowserRouter([
    {
        path: "",
        element: <App />,
        children: [
            {
                path: "*",
                element: <h1>hooooop</h1>,
            },
            {
                path: "/",
                element: (
                    <PrivateRoutes>
                        <League />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/profil/:uid",
                element: (
                    <PrivateRoutes>
                        <Profile />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/lig",
                element: (
                    <PrivateRoutes>
                        <League />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/yeni-sonuc",
                element: (
                    <PrivateRoutes>
                        <NewResult />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/sonuc-duzenle/:wm",
                element: (
                    <PrivateRoutes>
                        <EditResult />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/takimlar",
                element: (
                    <PrivateRoutes>
                        <Teams />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/draft/:phase",
                element: (
                    <PrivateRoutes>
                        <Draft />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/kap",
                element: (
                    <PrivateRoutes>
                        <Kap />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/admin",
                element: (
                    <PrivateRoutes>
                        <Admin />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/fikstur-olustur",
                element: (
                    <PrivateRoutes>
                        <ImportFixture />
                    </PrivateRoutes>
                ),
            },
            {
                path: "/kayit",
                element: <Register />,
            },
            {
                path: "/giris",
                element: <SignIn />,
            },
            {
                path: "/data",
                element: <ExportImport />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <StrictMode>
        <Provider store={store}>
            <AuthProvider>
                <ColorModeProvider>
                    <RouterProvider router={router} />
                </ColorModeProvider>
            </AuthProvider>
        </Provider>
    </StrictMode>
);
