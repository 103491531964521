import {
    Box,
    Paper,
    Typography,
    List,
    ListItem,
    Divider,
    useTheme,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const Fixture = ({ fixtures, weekNumber }) => {
    const theme = useTheme();

    return (
        <Paper elevation={1} sx={{ p: 2, mt: 2, width: "100%" }}>
            <Typography variant="h6" textAlign="center">
                Fikstür
            </Typography>
            <Box
                sx={{
                    marginTop: 2,
                    flexGrow: 1,
                }}
            >
                <Grid container spacing={2}>
                    {fixtures.map((fixture) => (
                        <Grid key={fixture.week} xs={12} sm={6}>
                            <Paper
                                variant="outlined"
                                sx={{
                                    width: "100%",
                                    mb: 3,
                                    p: 2,
                                    backgroundColor:
                                        fixture.week === weekNumber &&
                                        theme.palette.action.selected,
                                }}
                            >
                                <Typography variant="body1" gutterBottom>
                                    {fixture.week}. Hafta
                                </Typography>
                                <Divider />
                                <List>
                                    {fixture.matches.map((match, index) => (
                                        <ListItem
                                            key={index}
                                            sx={{ justifyContent: "start" }}
                                        >
                                            {match.homeTeam} vs {match.awayTeam}
                                        </ListItem>
                                    ))}
                                </List>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Paper>
    );
};

export default Fixture;
