import { useContext, useEffect, useState, createContext, useMemo } from "react";
import { app, db } from "../firebase/setup";
import {
    getAuth,
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signOut,
} from "firebase/auth";
import { getDoc, doc, updateDoc } from "firebase/firestore";
import { useDispatch } from "react-redux";
import { setLoading } from "../store/feedbackSlice";
import { openDB } from "idb";
import { collectionName } from "../constObjects";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

const getuserdata = async () => {
    const db = await openDB("firebaseLocalStorageDb");
    const userdata = await db.getAll("firebaseLocalStorage");
    return userdata.at(0)?.value;
};

export const AuthProvider = ({ children }) => {
    const auth = getAuth(app);

    const [currentUser, setCurrentUser] = useState(
        (async () => await getuserdata())()
    );

    const dispatch = useDispatch();

    const register = (email, password) => {
        return createUserWithEmailAndPassword(auth, email, password);
    };

    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password);
    };

    const logOut = () => {
        return signOut(auth);
    };

    useEffect(() => {
        const fetchUserData = async (user) => {
            dispatch(setLoading(true));
            const usersRef = doc(db, collectionName.users, user.uid);
            fetch("https://api.ipify.org?format=json")
                .then((response) => response.json())
                .then(async (data) => {
                    await updateDoc(usersRef, {
                        ip: data.ip,
                    });
                });
            const userSnap = await getDoc(usersRef);
            setCurrentUser({ ...userSnap.data(), uid: user.uid });
            dispatch(setLoading(false));
        };

        if (auth.currentUser) {
            fetchUserData(auth.currentUser);
        }

        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await fetchUserData(user);
            } else {
                setCurrentUser(null);
            }
        });
        return unsubscribe;
        //eslint-disable-next-line
    }, []);

    const value = useMemo(
        () => ({
            currentUser,
            register,
            signIn,
            logOut,
        }),
        //eslint-disable-next-line
        [currentUser]
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};
